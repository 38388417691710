import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Accordion from 'components/Accordion/Accordion';
import './FAQs.scss';
import { HashLink as Link } from 'react-router-hash-link';


export default (props) => {
  let defaultFaq = { section: 1, index: 0 };
  if (window.location.hash.length) {
    let values = window.location.hash.replace('#','').split('-');
    defaultFaq = {
      section: parseInt(values[0]),
      index: parseInt(values[1])
    }
  }

  const [accordion, setAccordion] = useState(defaultFaq);

  const handleAccordionClick = (index) => {
    if (index.index === accordion.index && 
        index.section === accordion.section) index = { section: 0, index: 0 };
    setAccordion(index);
  }

  const renderFaqs = (section, faqs) => {
    return faqs.map((faq, index) => {
      let id = { section, index };
      let key =  `${id.section}-${id.index}`;

      return (
        <Accordion
          id={key}
          key={key}
          open={`${accordion.section}-${accordion.index}` === key}
          onClick={handleAccordionClick.bind(this, id)}
          title={faq.question}
          answer={faq.answer}>
        </Accordion>
      )
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FAQs | Trebor Own It</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
        <h1 className="type-h2">FAQ<span className="lowercase">s</span></h1>
        <div className="faqs">
          <div className="faqs__section">
      
          
            <h2 className="type-h4 ">General promotional questions</h2>
            {
              renderFaqs(1, [{
                question: "1.	What is the Trebor ‘OWN IT’ promotion? ",
                answer: `
                  <p>Trebor ‘OWN IT’ is a promotion that gives you a chance to unlock your confidence in your own way through a £15,000/€ cash prize. Further to this, there is also the option to win 340 confidence boosting prizes which is made up of spa days, gym/wellbeing memberships, hair stylists and many more up to the value of £50/€.</p>
                `},{
                question: "2.	Who is the promotion open to?",
                answer: `
                  <p>The promotion is open to anyone that lives in the UK or ROI and is over 18.</p>
                `},{
                question: "3.	How can I enter? / How does it work?",
                answer: `
                  <p>You can see how to enter on the homepage of the website - scroll down to the bottom of the page to reach the ‘How Does It Work’ section. You can also find more details on the T&Cs page. UK residents must purchase a pack to enter. ROI no purchase necessary. </p>
                `},{
                  question: "4.	Which Trebor products are included in the promotion? ",
                answer: `
                  <p>You can buy any Trebor Pack to enter.</p>
                `},{
                  question: "5.	When can I enter?",
                answer: `
                  <p>The Grand Prize & Instant Win promotion runs from 10:00 24/07/23 – 16:59 31/05/24. The Wrap Up Draw runs between 17:00 31/05/24 – 16:59 31/08/24 </p>
                `},{
                  question: "6.	Do I need to retain my pack until I know the outcome?",
                answer: `
                  <p>No but you must retain your receipt in order to provide proof of purchase  -  section 2 T&Cs. No proof of purchase necessary for ROI.</p>
                `},{
                  question: "7.	Do I need internet?",
                answer: `
                  <p>You will need internet access to enter the promotion. You also need a valid email address.</p>
                `}
              ])
            }
          </div>
          <div className="faqs__section">
            <h2 className="type-h4 ">Prize details, claiming and redemption</h2>
            {
              renderFaqs(2, [{
                question: "8.	What prizes are available?",
                answer: `
                  <p>There are some incredible prizes up for grabs. These are summarised on the homepage of the website in the ‘Prizes’ section, and for full details you can read the T&Cs – section 8 & 10.</p>
                `},{
                question: "9.	How will I know if I’ve won?",
                answer: `
                  <p>If you are a confidence boosting prize winner, you’ll be notified immediately on the page with details of how to claim your prize. You’ll then be contacted via email to confirm your prize details, so keep an eye out and don’t forget to check your junk folder! If you are the grand prize winner, we’ll contact you at the end of the promotion in line with our T&Cs. Please see section 7 T&Cs. </p>
                `},{
                question: "10. No-one has been in contact in regards to my prize?",
                answer: `
                  <p>Please check section 12 in the T&Cs to see the time you should allow for us to contact you within. If this amount of time has passed and you have not been contacted (remember to check your junk email box), please contact our customer service team on <a href="tel:+44800818181" target="_blank" rel="noopener noreferrer">0800 818181</a> who will be able to assist you.</p>
                `},{
                question: "11. Is there a deadline for me to claim my prize?",
                answer: `
                  <p>Yes, please see section 12 in the T&Cs for further details.</p>
                `}
              ])
            }
          </div>
          <div className="faqs__section">
            <h2 className="type-h4 ">On pack codes and entry mechanic</h2>
            {
              renderFaqs(3, [{
                question: "12.	What codes do I need to enter to participate?",
                answer: `
                  <p>If you live in England, Scotland or Wales you must enter both the barcode and batch code as part of your entry, which can be found on the back of your Trebor product. The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code found within the box on the back of your pack located directly under the “Best Before” date – Section 11 T&Cs.</p>
                `},{
                question: "13. I can’t read my code",
                answer: `
                  <p>In some circumstances the batch code can be illegible or difficult to read. If you are having difficulties reading your code please contact our customer service team on please contact our customer service team on <a href="tel:+44800818181" target="_blank" rel="noopener noreferrer">0800 818181</a> who will be able to assist you  – section 11 T&Cs.</p>
                `},{
                question: "14. My code isn’t working, am I entering the right code?",
                answer: `
                  <p>It can be confusing as bars often feature many codes on the back of the pack for production reasons. Please double check you are entering the correct code within the required form field.</p>
                  <p>The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code found within the box on the back of your pack located directly under the “Best Before” date. No purchase necessary for ROI. </p>                  
                `},{
                question: "15. I’ve already entered my code; can I enter it again for another chance to win?",
                answer: `
                  <p>Users are limited to one entry per person per day. Max 20 entries per person over the promotional period. Max 1 prize type per household. </p>
                `},{
                question: "16. My code has been declined online and I get an error message, what can I do?",
                answer: `
                  <p>If the error message is ‘This code is not recognised’</p>
                  <p>Please check that you’re trying to enter the right code. Products often feature many codes on the back of the pack for production reasons. The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code found within the box on the back of your pack located directly under the “Best Before” date.</p>
                  <p>If the error message is ‘This code has already been used’</p>
                  <p>Remember, users are limited to one entry per person per email address per day. Only your first entry each day will be counted.</p>
                `},{
                question: "17. I entered the wrong email address. What do I do?",
                answer: `
                  <p>If you have entered an incorrect email address please contact our customer service team on please contact our customer service team on <a href="tel:+44800818181" target="_blank" rel="noopener noreferrer">0800 818181</a> who will be able to assist you. Users are limited to one entry per person per day. Max 20 entries per person over the promotional period. Max 1 prize type per household. </p>
                `}
              ])
            }
          </div>
        </div>
      </div>
    </section>
  </>;
}