import ValidationErrorCollection from './ValidationErrorCollection';

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const NAME_REGEX2 = /^(((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘)))+( (((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘))))*$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('firstName', 'Please enter a first name');
  required('email', 'Please enter an email address');
  required('emailConfirm', 'Please check and confirm your email');
  required('optIn', 'Please confirm you would like to receive future news');
  required('terms', 'Please confirm you agree to our terms & conditions and privacy policy');
  //required('legalAge', 'Please confirm you are over 18');
  //required('dob', 'Please enter your date of birth');

  if (command.firstName && !NAME_REGEX2.test(command.firstName)) {
    messages.addError('firstName', 'Please enter a valid first name');
  }

  if (command.email && !EMAIL_REGEX.test(command.email)) {
    messages.addError('email', 'Please enter a valid email address');
  }

  if (command.email && command.email.toLowerCase() !== command.emailConfirm.toLowerCase()) {
    messages.addError('emailConfirm', 'Please confirm your email');
  }
/*
  if (command.dob && !command.dob.isValid) {
    messages.addError('dob', command.dob.error || "Please enter a valid date of birth");
  }*/

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};