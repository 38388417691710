import React from 'react';

import timer from 'utils/timer';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import useSettingsContext from 'contexts/useSettingsContext';
import codeService from 'services/codeService';

import CodeEntryForm from 'containers/Forms/CodeEntryForm';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';

import AlertIcon from 'assets/images/alert.svg';
import BarcodeHelpImage from 'assets/images/campaign/how-2.png';
import BarcodeHelpImage2x from 'assets/images/campaign/how-2@2x.png';

const MAX_ATTEMPTS = 3;

export default (props) => {
  let [numAttempts, setNumAttempts] = React.useState(1);
  let [status, setStatus] = React.useState('Input');
  let competitionEntryState = useCompetitionEntryContext();
  let settings = useSettingsContext();

  async function onCodeSubmit(codes) {
    setStatus('Uploading');
    let isValid = await codeService.validateCodeEntry(codes);

    if (isValid) {
      setStatus('Valid');
      await timer.delay(2000);
      competitionEntryState.setCodes(codes);
      document.body.classList.remove("overflow-hidden-mediumdown");
      if (props.onEntry) {
        props.onEntry();
      }
    } else if (numAttempts >= MAX_ATTEMPTS) {
      setNumAttempts(1);
      setStatus('TooManyAttempts');
    } else {
      setNumAttempts(numAttempts + 1);
      setStatus('NotFound');
    }
  }

  function onDismissModal () {
    props.onDismiss();
    document.body.classList.remove("overflow-hidden-mediumdown");
  }

  function renderStatus() {
    if (props.show) {
      switch (status) {
        case 'Input': return (
          <Modal panel 
            icon={BarcodeHelpImage}
            icon2x={BarcodeHelpImage2x}
            alt="Barcode and batch code on a Trebor product"
            borderedIcon
            roundedIcon
            onConfirm={onDismissModal}
            onDismiss={onDismissModal}>
            <CodeEntryForm 
              onSubmit={onCodeSubmit} 
            />
          </Modal>

        )
        case 'NotFound': return (
          <Modal panel 
            icon={AlertIcon} 
            alt="warning sign"
            onConfirm={onDismissModal}
            className="modal--error"
            title="Not found">
            <p>
              Hmm, we don't recognise that code! Please try again. 
            </p>
            <Button title="Try again" onClick={() => setStatus('Input')} />
            <Link route title="See our FAQs" to="/faqs" onClick={onDismissModal}>See our FAQs</Link>
        </Modal>
        )
        case 'TooManyAttempts': return (
          <Modal panel 
            icon={AlertIcon} 
            alt="warning sign"
            onConfirm={onDismissModal}
            className="modal--error "
            title="Too many attempts">
            <p>
            Hmm, we don't recognise that code! Try again, or contact us if there's a problem.
            </p>
            <Button title="Try again" onClick={() => setStatus('Input')} />
            <Link route title="See our FAQs" to="/faqs" onClick={onDismissModal}>See our FAQs</Link> 
          </Modal>
        )
        case 'Uploading': return (
          <Modal loading />
        )
        case 'Valid': return (
          <Modal success />
        )
      }
    } else {
      return null;
    }
  }

  return renderStatus();
}