import React from 'react';
import classList from 'react-classlist-helper';
import './PrizeBlock.scss';

const PrizeBlock = (props) => {

  const mainClassMap = {
      "prize-block": true,
      "prize-block--highlight": props.highlight,
      "prize-block--horizontal": props.type === "horizontal",
      "prize-block--banner": props.type === "banner"
  };

  const classMap = {
    "prize-block__image": true,
    "prize-block__image--contain": props.containImage
};

  return (
    <article className={classList(mainClassMap)}>
      <div className={classList(classMap)}>
        <picture>
          { props.imageLarge &&
            <source media="(min-width: 768px)" srcSet={props.imageLarge2x + " 2x, " + props.imageLarge + " 1x"} type="image/png" />
          }
          <img src={props.image} srcSet={props.image2x + " 2x, " + props.image + " 1x"} alt={props.alt || props.title} />
        </picture>
      </div>

      {
        (props.title || props.description) ?
        (
          <div className="prize-block__info">
            <div className="prize-block__info-wrap">
              {
                props.title &&
                <h3 className="prize-block__title type-h3"  dangerouslySetInnerHTML={{__html: props.title}}></h3>
              }
              {
                props.description ?
                  (<div className="prize-block__info-copy" dangerouslySetInnerHTML={{__html: props.description}}></div>) : (<></>)
              }
            </div>
          </div>
        ) : (<></>)
      }
     
    </article>
  )
}

export default PrizeBlock;
