import React from 'react';
import { Redirect } from 'react-router-dom';
import Registration from './Registration';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import useSettingsContext from 'contexts/useSettingsContext';

export default () => {
  const settings = useSettingsContext();
  const competitionEntryState = useCompetitionEntryContext();
  let compState = settings.competitionStatus.openState;
  const isIreland = settings.country != 'GB';
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";
  let competitionIsMain = compPhaseId === "main";

  
  if (!settings.country) {
    return <Redirect to='/' />
  }
  

  if (competitionIsOpen && 
    ((competitionEntryState.batchcode && competitionEntryState.barcode) || isIreland )
     ) {
    return <Registration />
  } else {
    return <Redirect to='/' />
  }
}