import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import useEffectOnce from 'hooks/useEffectOnce';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import newsletterService from 'services/newsletterService';
import newsletterValidator from 'validators/newsletterValidator';

import Button from 'components/Button/Button';
import Checkbox from 'components/Forms/Checkbox';
import TextField from 'components/Forms/TextField';
import DateInput from 'components/Forms/DateInput';


import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.svg';
import Form, { createOnChangeHandler } from './Form';

import analyticsService from 'services/analyticsService';

import './Form.scss';

export default () => {
  let settingsContext = useSettingsContext();
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let competitionIsMopup = compPhaseId === "mop-up";

  let [settings, setSettings] = React.useContext(SettingsContext);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [email, setEmail] = useState('');
  let [emailConfirm, setEmailConfirm] = useState('');
  let [terms, setTerms] = useState(false);
  let [optIn, setOptIn] = useState(false);
  let [legalAge, setLegalAge] = useState(false);
  let [thirdPartyOptIn, setThirdPartyOptIn] = useState(false);
  let [dob, setDoB] = useState({
    isValid: false
  });
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [showRegMessage, setShowRegMessage] = useState(false);
  let [showSuccessMsgForm, setShowSuccessMsgForm] = useState(false);

  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);
  
  useEffectOnce(() => { 
    window.scrollTo(0, 0);
  });


  const createCommand = () => {
    let model = {
      firstName,
      //lastName,
      email,
      emailConfirm,
      country: settings.country,
      isMopupPhase: competitionIsMopup,
      optIn,
      legalAge,
      thirdPartyOptIn,
      dob,
      terms
    };

    if (dob && dob.isValid) {
      Object.assign(model, {
        dateOfBirthDay: dob.date.getDate(),
        dateOfBirthMonth: dob.date.getMonth() + 1,
        dateOfBirthYear: dob.date.getFullYear(),
        dob
      })
    }

    return model;
  }

  const onFormSuccess = (result) => {
  
      // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)
      analyticsService.logEvent("forms", "NewsletterForm", {
        action: "EmailCapture",
        email
      });
      analyticsService.logEvent("forms", "NewsletterForm", {
        action: "RegistrationSuccess"
      });


    setShowRegMessage(true);
    setShowSuccessMsgForm(true);

  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    document.body.classList.remove("overflow-hidden-mediumdown");
  }

  const onDismissRegModal = () => {
    setShowRegMessage(false);
    document.body.classList.remove("overflow-hidden-mediumdown");
  }

  
  /* RENDER */
  return <>  
    
    {showSuccessMsgForm &&
    <div>
      <p>&nbsp;</p>
      <p>Thank you for subscribing - keep one eye on your inbox!</p>
    </div>
   
    }

{!showSuccessMsgForm &&
    <Form 
        id="newsletter"
        submitTitle="Submit"
        onSuccess={onFormSuccess}
        formClass="form form--2columns"
        command={{
        model: createCommand,
        service: newsletterService.addNewsletterSignUp
        }}
        validation={{
        validator: newsletterValidator,
        onValidation: setValidation
        }}
        
    >
        
        {/* validation.errors.hasErrors && validation.acknowledged &&
        <ValidationSummary 
            errors={validation.errors}
            handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
        />*/
        }

        <fieldset className='form__group'>
          <fieldset className="form__fieldset">
          <TextField
              label="* First Name"
              id="firstName"
              onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
              value={firstName}
              hasErrors={!!validation.errors.firstName}
          />
          <ValidationMessage errors={validation.errors.firstName} />
          </fieldset>

          {/* <fieldset className="form__fieldset">
          <TextField 
              label="Last Name"
              id="lastName"
              onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
              value={lastName}
              hasErrors={!!validation.errors.lastName}
          />
          <ValidationMessage errors={validation.errors.lastName} />
          </fieldset>
          */}

          <fieldset className="form__fieldset">
          <TextField 
              label="* Email Address"
              id="email"
              type="text"
              onChange={createOnChangeHandler(validation, 'email', setEmail)}
              value={email}
              hasErrors={!!validation.errors.email}
          />
          <ValidationMessage errors={validation.errors.email} />
          </fieldset>

        
          <fieldset className="form__fieldset">
          <TextField 
              label="* Confirm Email Address"
              id="emailConfirm"
              autoComplete="off"
              type="text"
              onChange={createOnChangeHandler(validation, 'emailConfirm', setEmailConfirm)}
              value={emailConfirm}
              hasErrors={!!validation.errors.emailConfirm}
          />
          <ValidationMessage errors={validation.errors.emailConfirm} />
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="terms" 
              label="* I've read and agree to the Trebor Own It promotion <a href='/terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms & Conditions</a> and <a href='https://eu.mondelezinternational.com/privacy-notice?sc_lang=en&siteId=gnvqPqY%2bC4h0vOchgR1oOul5vzaH6HHs' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>."
              hasErrors={!!validation.errors.terms}
              onChange={createOnChangeHandler(validation, 'terms', setTerms)} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckTandCs"}}
            />
            <ValidationMessage errors={validation.errors.terms} />
            </fieldset>
        </fieldset>

{/*
        <fieldset className="form__fieldset">           

            <Checkbox 
              id="legalAge" 
              label="I confirm I am over 18 years old."
              hasErrors={validation.errors.hasErrors && !legalAge}
              onChange={createOnChangeHandler(validation, 'legalAge', setLegalAge)} 
            />
            <ValidationMessage errors={validation.errors.legalAge} />            

            <label
              className="textfield__label textfield__label--small"
              htmlFor="">
              Fancy a birthday treat? Tell us your DOB and opt into our marketing comms for a surprise in your inbox on the big day (optional)
            </label>

            <label
              className="textfield__label"
              htmlFor="">
              Date of birth
              <span className="textfield__label-info">For example 31 07 1990</span>
            </label> 

            <DateInput
              className="date-select"
              minDate={minBirthDate}
              minDateErrorMessage="You must be over 18 to sign up for the newsletter"
              onChange={createOnChangeHandler(validation, 'dob', setDoB)}
              value={dob}
             
            />
            <ValidationMessage errors={validation.errors.dateOfBirthDay || validation.errors.dob} />
          </fieldset>
 */}

        <fieldset className='form__group'>
          <fieldset className="form__fieldset">
          <Checkbox 
            id="optIn" 
            label="* If you'd like to receive future news, competitions and offers from Trebor by email, please tick this box. If you no longer wish to receive emails, you may <a href='https://contactus.mdlzapps.com/form?siteId=gnvqPqY%2bC4h0vOchgR1oOul5vzaH6HHs' target='_blank' rel='noopener noreferrer'>unsubscribe here</a>."
            onChange={createOnChangeHandler(validation, 'optIn', setOptIn)} 
            hasErrors={validation.errors.hasErrors && !optIn}
            checkboxAnalytics={{evnt: "form", category: "NewsletterForm", action: "CheckOptIn"}}
            />
            <ValidationMessage errors={validation.errors.optIn} />

          <Checkbox 
              id="thirdPartyOptIn" 
              label="Tick this box to grant Trebor permission to use your personal information to help us ensure our future communications with you, either through our partners or across third party platforms, are more relevant to you. Trebor is an affiliate brand of Mondelez International."
              onChange={setThirdPartyOptIn} 
              checkboxAnalytics={{evnt: "form", category: "NewsletterForm", action: "CheckThirdPartyOptIn"}}
          />
          </fieldset>
        </fieldset>

        <ReCaptcha captchaSettings={settingsContext.captchaSettings} />

        { validation.errors.hasErrors && !validation.acknowledged &&
        <Modal 
            panel 
            hideClose
            className=""
            alt="warning sign"
            icon={AlertIcon}
            onDismiss={onDismissModal}
            onConfirm={onDismissModal}
            title="NICE TRY!">
            <ValidationSummary 
            modal
            defaultMessage="Please check you have entered all fields correctly."
            errors={validation.errors}
            handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
            />
            { !validation.errors.globalErrors.length ? (
                <Button title="Try again" onClick={onDismissModal} className="button--wide" />
            ) : (
                <Button title="Back to homepage" href="/"  className="button--wide" />
            )
            }
        </Modal>
        }
    </Form>
  }
        
    { showRegMessage &&
      <Modal 
          panel 
          className="modal--single-title"
          onDismiss={onDismissRegModal}
          onConfirm={onDismissRegModal}
          title="Thank you for Subscribing!">
      </Modal>
    }
  </>;
}