import React from 'react';
import classList from 'react-classlist-helper';
//import './SummaryTsandCs.scss';

const SummaryTsandCs = (props) => {
  const classMap = {
    "layout-wrapper": true,
    [props.className]: !!props.className,
  };

  return (
    <section className={classList(classMap)} >
      <div className="layout-container layout-container--center">
        
        <div className='summary'>
            <div className='summary__text'><strong>Summary Terms and Conditions:</strong></div>
            <p className='summary__text'>UK & ROI (excl. IoM & CI), 18+ only, <strong>Grand Prize & Instant Win:</strong> 10:00 24/07/2023 – 16:59 <strong>31/05/2024</strong> Internet access required. Purchase any pack of Trebor, visit <a href='https://www.trebor.co.uk/' target='_blank' rel='noopener noreferrer'>www.trebor.co.uk</a> and select your region. No purchase needed for ROI. Enter your full name, email address, phone number & your barcode & batch code for UK & full name, email address, phone number & full postal address for ROI to find out instantly if you’ve won a prize and to be entered into the Grand Prize draw. <strong>(Grand Prize draw will take place within 5 working days from the end of the Promotion Period). Grand Prize:</strong> 1 x £15,000/€. <strong>Instant Win Prizes:</strong> 340 x choice of vouchers for personal training sessions, restaurant vouchers, yoga classes, and many more up to the value of £50/€ (subject to individual terms and conditions). <strong>Wrap Up Draw:</strong> Entrants between 17:00 31/05/24 – 16:59 31/08/24 will be placed in a final draw for a chance to win £250/€. <strong>Retain receipt to claim.</strong> Max 1 entry per person per day. Max 20 entries per person over the promotional period. Max 1 prize type per household. Visit website for full <a href='/terms-and-conditions' target='_blank' rel="noopener noreferrer" >T&Cs</a>. Promoter: Mondelez Europe Services GmbH – UK Branch with registered office at Cadbury House, Sanderson Road, Uxbridge, UB8 1DH.</p>
          </div>
        </div>
      
    </section>
  )
}

export default SummaryTsandCs;
