import React from 'react';
import product1Mob from 'assets/images/campaign/product-1-mob-new.png';
import product1Mob2x from 'assets/images/campaign/product-1-mob-new@2x.png';
import product1 from 'assets/images/campaign/product-1.png';
import product12x from 'assets/images/campaign/product-1@2x.png';
import product2 from 'assets/images/campaign/product-2.png';
import product22x from 'assets/images/campaign/product-2@2x.png';
import product4 from 'assets/images/campaign/product-4.png';
import product42x from 'assets/images/campaign/product-4@2x.png';
import product4desk from 'assets/images/campaign/product-4-desk.png';
import product4desk2x from 'assets/images/campaign/product-4-desk@2x.png';

import './ProductRange.scss';

export default (props) => {

  return (
    <section id="product-range" className="layout-wrapper layout-wrapper--padding layout-wrapper--bg product-range">
      <div className="layout-container layout-container--center layout-container--products">
        <h2 className="type-h2 ">Don't be shy. Own It. <br /> Take a look at Trebor's wide range</h2>

        <div className="product-range-list">
          <div className='product-range-list__item product-range-list__item--first-item'>
            <picture>    
              <source media="(min-width: 768px)" srcSet={product12x + " 2x, " + product1 + " 1x"} type="image/png" />
              <img  className="product-range-list__img product-range-list__img--first" src={product1Mob} srcSet={product1Mob2x + " 2x, " + product1Mob + " 1x"} alt="Packs of Trebor Extra Strong Mints"  />  
            </picture> 
            <div className='product-range-list__copy'>
              <p>Confidence boosting<br/> freshness</p>
            </div>
          </div>

          <div className='product-range-list__item product-range-list--arrow-left product-range-list--stars'>
            <img className="product-range-list__img" src={product2} srcSet={product22x + " 2x, " + product2 + " 1x"} alt="Packs of Trebor Soft Mints" />
            <div className='product-range-list__copy'>
              <p>Irresistibly chewy<br/> and subtly minty</p>
            </div>
          </div>

          <div className='product-range-list__item product-range-list--arrow-bottom'>
            <picture>  
              <img className="product-range-list__img" src={product4} srcSet={product42x + " 2x, " + product4 + " 1x"} alt="Pack of Trebor Extra Strong Minis" />
            </picture> 
            <div className='product-range-list__copy'>
              <p>Mini but<br/> mighty</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
  
}