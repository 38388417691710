import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useEffectOnce from 'hooks/useEffectOnce';

import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import competitionEntryValidator from 'validators/competitionEntryValidator';
import reCaptchaService from 'services/reCaptchaService';

import Button from 'components/Button/Button';
import Checkbox from 'components/Forms/Checkbox';
import TextField from 'components/Forms/TextField';
import DateInput from 'components/Forms/DateInput';

import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.svg';
import Form, { createOnChangeHandler } from './Form';


import analyticsService from 'services/analyticsService';

import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';

import './Form.scss';

export default () => {
  let history = useHistory();
  let competitionEntryContext = useCompetitionEntryContext();
  let settingsContext = useSettingsContext();
  let compState = settingsContext.competitionStatus.openState;
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";
  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);

  let [settings, setSettings] = React.useContext(SettingsContext);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [email, setEmail] = useState('');
  let [emailConfirm, setEmailConfirm] = useState('');
  let [phoneNumber, setPhoneNumber] = useState('');
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [city, setCity] = useState('');
  let [county, setCounty] = useState('');
  let [postcode, setPostcode] = useState('');
  let [accountOptIn, setAccountOptIn] = useState(false);
  let [password, setPassword] = useState('');
  let [passwordConfirm, setPasswordConfirm] = useState('');
  let [teamCode, setTeamCode] = useState('');
  let [terms, setTerms] = useState(false);
  let [legalAge, setLegalAge] = useState(false);
  let [dob, setDoB] = useState({
    isValid: false
  });
  let [optIn, setOptIn] = useState(false);
  let [thirdPartyOptIn, setThirdPartyOptIn] = useState(false);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);

  const [firstNameReadOnly, setFirstNameReadOnly] = useState(false);
  const [lastNameReadOnly, setLastNameReadOnly] = useState(false);
  const [emailReadOnly, setEmailReadOnly] = useState(false);
  const [dobReadOnly, setDobReadOnly] = useState(false);
  

  const [phoneNumberReadOnly, setPhoneNumberReadOnly] = useState(false);


  // useEffectOnce(() => { 
  //   window.scrollTo(0, 0);    
  // });

  useEffect(()=>{
    setDoB(null);
  }, [optIn])

  useEffect(() => {
    let account = settings.account;
    if (!account) return;


    if (account.firstname) {
      setFirstName(account.firstname);
      setFirstNameReadOnly(true);
    }

    if (account.lastname)  {
      setLastName(account.lastname);
      setLastNameReadOnly(true);
    }

    if (account.email) {
      setEmail(account.email);
      setEmailConfirm(account.email);
      setEmailReadOnly(true);
    }

    if (account.phoneNumber) {
      setPhoneNumber(account.phoneNumber);
      setPhoneNumberReadOnly(true);
    }

    if (account.birthday) {
      setDoB(account.birthday);
      setDobReadOnly(true);
    }

    

  }, [settings.account])
  
  const createCommand = () => {
    let model = {
      firstName,
      lastName,
      email,
      emailConfirm,
      country: settings.country,
      phoneNumber,
      dob,
      address1,
      address2,
      city,
      county,
      postcode,
      retailerId: null,      
      terms,
      legalAge,
      isMopupPhase: competitionIsMopup,
      barcode: competitionEntryContext.barcode,
      batchcode: competitionEntryContext.batchcode, 
      teamCode,
      tier1PrizePublicId: null,     
      optIn,
      thirdPartyOptIn,
      accountOptIn,
      password,
      passwordConfirm,
      recaptcha2
    };

    if (dob && dob.isValid) {
      Object.assign(model, {
        dateOfBirthDay: dob.date.getDate(),
        dateOfBirthMonth: dob.date.getMonth() + 1,
        dateOfBirthYear: dob.date.getFullYear(),
        dob
      })
    }
    
    return model;
  }

  var handleReCaptchaV2 = (val) => {
    
    if(val !== null && val !== ""){     
      //setRecaptcha2(val); 
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }

  useEffectOnce(() => {
    
    window.setTimeout(function(){
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
        
            window.grecaptcha.ready(() => {              
              
              window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6LdL2fUjAAAAAOoc2lfJC0TdN5ovsuxgkeJcSUv1',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
              });
            });
        }
      }catch{}
    }, 1000);
  });

  const onFormSuccess = (result) => {
    analyticsService.logEvent("forms", "Forms", {
      action: "RegistrationSuccess"
    });

    // forward to thank-you page
    competitionEntryContext.reset();
    competitionEntryContext.setEmail(email);
    competitionEntryContext.setResult(result);

    // Update csfr token and flag as logged in
    setSettings({...settings, country: result.country });

    let urlData = !competitionIsMopup ? `/${result.entryPublicId}` : '';
    history.push(`/thankyou${urlData}`);
  }

  const onDismissModal = () => {

    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }


  /* RENDER */
  return <>  
    <Helmet>
      <meta charSet="utf-8" />
      <title>Register | Trebor Own It</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
        <h1 className="type-h2 ">Your Details</h1>
       
        <p className="form__intro">
          We just need some additional information from you to check that you’re eligible to enter and so we can contact you if you’re one of the lucky winners! If you are, then make sure you have your receipt so you're able to claim your prize.
        </p>
        <p className="form__intro">
          Fields marked with * are mandatory.
        </p>

        <Form 
          id="registration"
          submitTitle="Enter"
          onSuccess={onFormSuccess}
          formClass="form form--slim"
          command={{
            model: createCommand,
            service: competitionEntryService.addCompetitionEntry
          }}
          validation={{
            validator: competitionEntryValidator,
            onValidation: setValidation
          }}
          submitAnalytics={[
            { evnt: "form", category: "RegistrationForm", action: "EmailCapture", label: email} ,
            (dob && dob.isValid) &&
            { evnt: "form", category: "RegistrationForm", action: "DateOfBirth", label: dob.date} 
          ]}
        >
          
          { validation.errors.hasErrors && validation.acknowledged &&
            <ValidationSummary 
              errors={validation.errors}
              handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
            />
          }

          <fieldset className="form__fieldset">
            <TextField 
              label="*First Name"
              id="firstName"
              onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
              value={firstName}
              hasErrors={!!validation.errors.firstName}
              readonly={firstNameReadOnly}
            />
            <ValidationMessage errors={validation.errors.firstName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField 
              label="*Last Name"
              id="lastName"
              onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
              value={lastName}
              hasErrors={!!validation.errors.lastName}
              readonly={lastNameReadOnly}
            />
            <ValidationMessage errors={validation.errors.lastName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField 
              label="*Email Address"
              id="email"
              type="email"
              onChange={createOnChangeHandler(validation, 'email', setEmail)}
              value={email}
              hasErrors={!!validation.errors.email}
              readonly={emailReadOnly}
            />
            <ValidationMessage errors={validation.errors.email} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField 
              label="*Confirm Email Address"
              id="emailConfirm"
              autoComplete="off"
              type="email"
              onChange={createOnChangeHandler(validation, 'emailConfirm', setEmailConfirm)}
              value={emailConfirm}
              hasErrors={!!validation.errors.emailConfirm}
              readonly={emailReadOnly}
            />
            <ValidationMessage errors={validation.errors.emailConfirm} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField 
              label="*Mobile Number"
              id="phoneNumber"
              info={ settings.country === "UK" ? `Please ensure this is a valid UK mobile number`:`Please ensure this is a valid Irish mobile number` }
              type="tel"
              onChange={createOnChangeHandler(validation, 'phoneNumber', setPhoneNumber)}
              value={phoneNumber}
              autoComplete="off"
              hasErrors={!!validation.errors.phoneNumber}
              readonly={phoneNumberReadOnly}
              tooltipText="Required so we can contact you should you win"
              onDismiss={onDismissModal}
            />
            <ValidationMessage errors={validation.errors.phoneNumber} />
        </fieldset>

          { settings.country === "ROI" && 
            <>
          
              <fieldset className="form__fieldset">
                <TextField 
                  label="*Address line 1"
                  info="Please ensure this is a valid Irish address"
                  id="address1"
                  onChange={createOnChangeHandler(validation, 'address1', setAddress1)}
                  value={address1}
                  hasErrors={!!validation.errors.address1}
                />
                <ValidationMessage errors={validation.errors.address1} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="Address line 2"
                  id="address2"
                  onChange={createOnChangeHandler(validation, 'address2', setAddress2)}
                  value={address2}
                  hasErrors={!!validation.errors.address2}
                />
                <ValidationMessage errors={validation.errors.address2} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="*City"
                  id="city"
                  onChange={createOnChangeHandler(validation, 'city', setCity)}
                  value={city}
                  hasErrors={!!validation.errors.city}
                />
                <ValidationMessage errors={validation.errors.city} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="*County"
                  id="county"
                  onChange={createOnChangeHandler(validation, 'county', setCounty)}
                  value={county}
                  hasErrors={!!validation.errors.county}
                />
                <ValidationMessage errors={validation.errors.county} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label={ settings.country === "ROI" ? `*Eircode`:`*Postcode` }
                  id="postcode"
                  onChange={createOnChangeHandler(validation, 'postcode', setPostcode)}
                  value={postcode}
                  hasErrors={!!validation.errors.postcode}
                />
                <ValidationMessage errors={validation.errors.postcode} />
              </fieldset>

            </>
          }

          
          
          <fieldset className="form__fieldset">
            <Checkbox 
              id="legalAge" 
              label="* I confirm I am over 18 years old."
              hasErrors={validation.errors.hasErrors && !legalAge}
              onChange={createOnChangeHandler(validation, 'legalAge', setLegalAge)} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckLegalAge"}}
            />
            <ValidationMessage errors={validation.errors.legalAge} />            
          </fieldset>
         
          <fieldset className="form__fieldset">
            <Checkbox 
              id="optIn" 
              label="If you'd like to receive future news, competitions and offers from Trebor by email, please tick this box. If you no longer wish to receive emails, you may <a href='https://contactus.mdlzapps.com/form?siteId=gnvqPqY%2bC4h0vOchgR1oOul5vzaH6HHs' target='_blank' rel='noopener noreferrer'>unsubscribe here</a>."
              onChange={setOptIn} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckOptIn"}}
            />
            {
                optIn && 
                <>
                <br/>
                  <div className='form__date-entry'>
                    <label
                      className="textfield__label textfield__label--small"
                      htmlFor="">
                      If you would like a birthday offer in your inbox, tell us your date of birth (optional). We also use this information to personalise your future experiences with Cadbury.
                    </label>
                    <DateInput
                      className="date-select"
                      minDate={minBirthDate}
                      minDateErrorMessage="You must be over 18 to enter this promotion"
                      onChange={createOnChangeHandler(validation, 'dob', setDoB)}
                      value={dob}
                    />
                    <ValidationMessage errors={validation.errors.dateOfBirthDay || validation.errors.dob} />
                  </div>
                  </>
              }
          </fieldset>


        <fieldset className="form__fieldset">
            <Checkbox 
              id="thirdPartyOptIn" 
              label="Tick this box to grant Trebor permission to use your personal information to help us ensure our future communications with you, either through our partners or across third party platforms, are more relevant to you. Trebor is an affiliate brand of Mondelez International."
              onChange={setThirdPartyOptIn} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckThirdPartyOptIn"}}
            />
            
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="terms" 
              label="* I've read and agree to the Trebor Own It promotion <a href='/terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms & Conditions</a> and <a href='https://eu.mondelezinternational.com/privacy-notice?sc_lang=en&siteId=gnvqPqY%2bC4h0vOchgR1oOul5vzaH6HHs' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>."
              hasErrors={validation.errors.hasErrors && !terms}
              onChange={createOnChangeHandler(validation, 'terms', setTerms)} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckTandCs"}}
            />
            <ValidationMessage errors={validation.errors.terms} />
            </fieldset>
          

          <ReCaptcha captchaSettings={settingsContext.captchaSettings} />
            <div className="g-recaptcha-v2" data-sitekey="6LdL2fUjAAAAAOoc2lfJC0TdN5ovsuxgkeJcSUv1"
            data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
            <ValidationMessage errors={validation.errors.recaptcha2} />

          { validation.errors.hasErrors && !validation.acknowledged &&
            <Modal 
              panel 
              hideClose
              alt="warning sign"
              icon={AlertIcon}
              onDismiss={onDismissModal}
              onConfirm={onDismissModal}
              title="NICE TRY!">
              <ValidationSummary 
                modal
                defaultMessage="Please check you have entered all fields correctly."
                errors={validation.errors}
                handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
              />
              { !validation.errors.globalErrors.length ? (
                  <Button title="Try again" onClick={onDismissModal} className="button--wide" />
                ) : (
                  <Button title="Back to homepage" href="/"  className="button--wide" />
                )
              }
            </Modal>
          }
          
        </Form>

        <div className="form-text-container">Please make sure you have read and understand the <a href='https://eu.mondelezinternational.com/privacy-notice?sc_lang=en&siteId=gnvqPqY%2bC4h0vOchgR1oOul5vzaH6HHs' target='_blank'><strong>Privacy Policy</strong></a> and agree to the processing of your personal data.</div>

      </div>

    </section>

    <SummaryTsandCs />

  </>;
}