import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Redirect, useParams } from 'react-router-dom';

import Button from 'components/Button/Button';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import analyticsService from 'services/analyticsService';
import NewsletterForm from 'containers/Forms/Newsletter';

import WinnerImage from 'assets/images/campaign/thankyou-winner.png';
import WinnerImage2x from 'assets/images/campaign/thankyou-winner@2x.png';
import WinnerImageMob from 'assets/images/campaign/thankyou-winner--mobile.png';
import WinnerImageMob2x from 'assets/images/campaign/thankyou-winner--mobile@2x.png';

import LoserImage from 'assets/images/campaign/thankyou-loser.png';
import LoserImage2x from 'assets/images/campaign/thankyou-loser@2x.png';
import LoserImage_euro from 'assets/images/campaign/thankyou-loser--euro.png';
import LoserImage2x_euro from 'assets/images/campaign/thankyou-loser--euro@2x.png';


import SpaceshipStripImage from 'assets/images/campaign/spaceship-strip--mobile.png';
import SpaceshipStripImage2x from 'assets/images/campaign/spaceship-strip--mobile@2x.png';
import SpaceshipStripImageLarge from 'assets/images/campaign/spaceship-strip.png';
import SpaceshipStripImageLarge2x from 'assets/images/campaign/spaceship-strip@2x.png';

import NpsBanner from 'components/NpsBanner/NpsBanner';
import SocialButtons from 'components/SocialButtons/SocialButtons';
import ProductRange from 'components/ProductRange/ProductRange';
import timer from 'utils/timer';

import './Thankyou.scss';



export default () => {
  let { entryPublicId } = useParams();
  let [settings, setSettings] = React.useContext(SettingsContext);

  let settingsContext = useSettingsContext();

  let compState = settingsContext.competitionStatus.openState;
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";

  let competitionEntryContext = useCompetitionEntryContext();
  let [entry, setEntry] = useState(competitionEntryContext.result);


  let [showNps, setShowNps] = useState(null);

  let winningEventFired = false;


  useEffect(() => {
    async function fetchData() {
      let result = await competitionEntryService.getCompetitionEntry(entryPublicId);
      if (result !== null && result !== undefined){
        setEntry(result);
      }
      
    }
    
    if (entryPublicId && !entry) {
      fetchData();
      //timer.delay(1500).then(() => setShowNps(true));
    }

    
  }, [entry]);

  
  if (!competitionIsMopup && (!entryPublicId)) {
    return (
      <Redirect to="/" />
    )
  }

  const renderNpsBanner = (entryPublicId) => {
    if (!entryPublicId) return null;
    return <NpsBanner onClose={() => setShowNps(false)} competitionEntryPublicId={entryPublicId} show={showNps} />
  }

  const renderWinner = () => {  
   if(!winningEventFired)
    {
      analyticsService.logEvent('event', 'Prize', {
        action: entry.prizePublicId
      });

      winningEventFired = true;
    }
  
    return (
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Get in! You've won a confidence boosting prize | Trebor Own It</title>
      </Helmet> 
      <section className="layout-general layout-wrapper layout-wrapper thankyou thankyou--stars">
        <div className="layout-container layout-container--center ">
          
          <picture>
            <source media="(min-width: 768px)" srcSet={WinnerImage2x + " 2x, " + WinnerImage + " 1x"} type="image/png" />
            <img className="thankyou__img" src={ WinnerImageMob } srcSet={WinnerImageMob2x + " 2x, " + WinnerImageMob + " 1x"} alt="Get in! You’ve won a confidence boosting prize" />
          </picture>
          <div className="thankyou__copy ">
            <p className='type-hpara'>Now pick something that'll make you feel good inside and out. What's it gonna be? A big night out to celebrate? A fitness kick with a new gym session? A much needed mindfulness session?</p>            
            <p className="thankyou__disclaimer">Note: All wins have to be verified as valid entries, so please <strong>keep your receipt</strong> as you may need to provide proof.</p>
            <p className="thankyou__disclaimer">Winners will be notified via email within 3 working days, and have 14 days to claim their prize.</p>   
            <p className="thankyou__disclaimer thankyou__disclaimer--message"><strong>And you’ve been entered in the draw to win a cool £/€15K in cash. Winners will be drawn within 5 working days from the end of the promotion period</strong></p>
          </div>
        </div>

      </section>
      <ProductRange />
      </>
    );
  }
  
  const renderLoser = () => {
    return (
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>OH NO | Trebor Own It</title>
      </Helmet> 
      <section className="layout-general layout-wrapper">
        <div className="layout-container layout-container--center thankyou">
          <h1 className="type-h2 ">Oh No</h1>
          <div className="thankyou__copy">
            <p className='type-hpara'> You didn't win this time, but don't let that slow you down.</p>
            <p className='type-hpara'>Come back tomorrow and try again, you've got this!</p>
            <p className='type-hpara'><strong>The GOOD NEWS is you're still in the draw to win a cool {entry != null ? (entry.country === "ROI" ? "€" : (entry.country === "UK" ? "£" : "£/€")) : "£/€"}15K in cash.</strong></p>
            <p className='type-hpara'>Winners will be drawn within 5 working days from the end of the promotion period.</p>
          </div>
          <div className='thankyou__img-wrapper'>
            {
              ( entry !== null && entry.country != undefined && entry.country === "ROI") ? (
                <img className="thankyou__img" src={ LoserImage_euro } srcSet={LoserImage2x_euro + " 2x, " + LoserImage_euro + " 1x"} alt="A chance to win €15K" />          
              ) : (
                <img className="thankyou__img" src={ LoserImage } srcSet={LoserImage2x + " 2x, " + LoserImage + " 1x"} alt="A chance to win £15K" />          
              )
            }
          </div>
        </div>
      </section>
      <section className="layout-wrapper home__signup">
          <div className="layout-container ">
            <div className='home__signup__form-block'>
              <div className='type-h2'>Your Inbox.<br/>Own It</div>
              <p>Do you want to be kept in the know about all things Trebor? Of course you do! Just tick the boxes and we’ll send you the latest on new releases, competitions and more.</p>
              <NewsletterForm />
            </div>                       
          </div>
        </section>
      <ProductRange />
      </>
    );
  }

  const renderMopup = () => {
    return (
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thanks for entering | Trebor Own It</title>
      </Helmet> 
        <section className="layout-general layout-wrapper thankyou--mopup">
          <div className="layout-container layout-container--center">
            <picture>
              {
                (SpaceshipStripImageLarge && SpaceshipStripImageLarge2x) &&
                <source media="(min-width: 768px)" srcSet={SpaceshipStripImageLarge2x + " 2x, " + SpaceshipStripImageLarge + " 1x"} type="image/png" />
              }
              <img className="claim__image" src={SpaceshipStripImage} srcSet={ SpaceshipStripImage2x + " 2x, " + SpaceshipStripImage + " 1x"} alt="Illustration of rocket ship flying through the stars" />
            </picture>
            <h1 className="type-h2">Thanks for entering</h1>
            <div className="thankyou__copy ">
              <p className='type-hpara'>We'll be in touch soon if you're a lucky winner.</p>
              <br />
              <p className='type-hpara'>In the meantime why not keep up to speed with all things Trebor and follow us on social?</p>
            </div>
            
            <SocialButtons hasIlustrations />
            
          </div>
        </section>
        <ProductRange />
      </>
    );
  }
  

  return (
    <>
    {
        competitionIsMopup ? (
          renderMopup()
        ): (
          (entry !== null && entry.hasWon) ? (
            renderWinner()
          ) : (
            renderLoser()
          )
        )
    }
    </>
  );
  
 
}
