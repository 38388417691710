import React from 'react';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import useSettingsContext from 'contexts/useSettingsContext';

import './Policies.scss';

export default (props) => {
  const settings = useSettingsContext();
  const competitionEntryState = useCompetitionEntryContext();
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";


  if (competitionIsOpen || competitionIsMopup) {
    return <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions | Trebor Own It</title>
      </Helmet>
      <section className="layout-general layout-wrapper">
        <div className="layout-container layout-container--slim layout-container--center">
          <h1 className="type-h2">T&C<span className="lowercase">s</span></h1>
          <div className="policies">
            <ol class="test">
              <li><p>This Promotion is open to residents of the United Kingdom and Republic of Ireland (excluding the Isle of Man and the Channel Islands) aged 18 or over, excluding employees of the Promoter, its agents and anyone professionally associated with this Promotion.</p></li>
              <li><p>Purchase is necessary in UK only. <strong>Please retain an itemised receipt that states the time and date of purchase prior to entry, but within the Promotion Period, as this may be required for validation and in order for the winner to receive their prize.</strong> Internet access is required to enter.</p></li>
              <li><p>Please note that while we want to encourage you to participate and enjoy the benefits of the Promotion we believe in responsible consumption of our products. DO NOT purchase more product than you can reasonably use or consume just to participate in this Promotion.</p></li>
              <li>
                <p><strong>Promotion Period:</strong></p>
                <ol type="a">
                  <li><p><strong>Instant Win & Grand Prize Draw Period:</strong> Enter between 10:00 BST on the 24 July 2023 and 16:59 BST on the 31 May 2024 inclusive.</p></li>
                  <li><p><strong>Wrap-Up Draw Period:</strong> Enter between 17:00 BST on the 31 May 2024 and 16:59 BST on the 31 August 2024 inclusive. </p></li>
                </ol>
              </li>
              <li>
                <p><strong>To Enter:</strong> </p>
                <ol type="a">
                  <li><p><strong>UK residents:</strong> Purchase any pack of Trebor, visit <a href="https://www.trebor.co.uk/" target="_blank" rel="noopener noreferrer">www.trebor.co.uk</a> and select your region. Complete the online form providing your full name, email address, phone number and your barcode & batch code to find out instantly if you've won a prize and to be entered into the Grand Prize draw.</p></li>
                  <li><p><strong>ROI residents:</strong> Visit <a href="https://www.trebor.co.uk/" target="_blank" rel="noopener noreferrer">www.trebor.co.uk</a> and select your region. Complete the online form providing your full name, email address, phone number and full postal address to find out instantly if you've won a prize and to be entered into the Grand Prize draw.</p></li>
                </ol>
              </li>
              <li><p>Max 1 entry per person per day. For UK residents - One new purchase required for each entry. Maximum of 20 entries per person during the entire Promotion Period. One itemised receipt per entry. Each itemised receipt must state the time and date of purchase, which must pre-date the entry date and time.  Max 1 prize type per household.</p></li>
              <li>
                <p><strong>Winner Selection:</strong> </p>
                <ol type="a">
                  <li><p><strong>Instant Win:</strong> During the Promotion Period 340 “winning moments” (days, hours, minutes, seconds) have been randomly selected by an independent verification service. The first valid entry on or after each winning moment will be awarded the prize. If no entry is received between one winning moment to the next, the prize from that winning moment will be rolled over, creating an additional winning moment. All 340 prizes are available to be won. 
Following the end of the Promotional Period, in the event of any Instant Win Prizes that are rejected, undelivered, unclaimed or returned to sender throughout the Promotional Period, the Promoter reserves the right to request that an independent person conducts a random draw for all non-winning entrants. As this depends wholly on what is won during the promotion, the number of prizes that may be awarded by this route is unknown.
</p></li>
                  <li><p><strong>Grand Prize Draw:</strong> 1 winner will be randomly selected from all valid entries received during the Promotion Period. For your total confidence, the prize draw will be conducted by PromoVeritas, the independent promotional verification service, within 5 working days from the end of the Promotion Period.</p></li>
                  <li><p><strong>Wrap-Up Draw:</strong> 1 winner will be randomly selected from all valid entries received during the Wrap Up Draw Period. The prize draw will be conducted by PromoVeritas, within 7 working days of the close of the Wrap Up Draw Period.</p></li>
                </ol>
              </li>
              <li>
                <p><strong>The Prizes:</strong></p>
                <ol type="a">
                  <li>
                    <p><strong>Instant Win Prizes:</strong> There are a total of 340 prizes to be won. The following prizes are available to be won, and winners will each have a selection of the following vouchers up to the value of £50/€ - vouchers are subject to use within the specified locations only and further details and terms and conditions are available below:</p>
                    <ol type="i">
                      <li>
                        <strong>UK Vouchers:</strong>
                        <ol type="1">
                          <li>Spa and Beauty Indulgent Choice Voucher - GB and NI</li>
                          <li>Hair Stylist Voucher - EG Treatwell voucher - GB and NI</li>
                          <li>The Dining out Gift Card - GB and NI</li>
                          <li>Restaurant Favourites Gift Voucher - GB and NI</li>
                          <li>Cineworld Gift Card - GB</li>
                          <li>Odeon Gift Card - GB and NI </li>
                          <li>Vue Cinema Gift Card - GB and NI</li>
                          <li>Omniplex Cinema Gift Card - NI</li>
                          <li>IMC Cinemas Gift Card - NI</li>
                          <li>Fashion Choice Gift Voucher - £50 - GB and NI</li>
                          <li>Yoga Classes Choice Voucher - GB</li>
                          <li>Lifestyle Gift Voucher - GB and NI</li>
                          <li>MEvME Personal Training Gift Voucher - GB and NI </li>
                          <li>Toni and Guy Voucher - GB </li>
                          <li>Confidence and Authenticity Masterclass Programme - GB and NI </li>
                          <li>City Academy Dance Voucher - GB </li>
                        </ol>
                      </li>
                      <li>
                        <strong>ROI Vouchers:</strong>
                        <ol type="1">
                          <li>AllGifts.ie Voucher - ROI</li>
                          <li>AllGifts.ie Restaurant Voucher - ROI</li>
                          <li>Vue Cinema Gift Card - ROI</li>
                          <li>Omniplex Cinema Gift Card - ROI</li>
                          <li>IMC Cinemas Gift Card - ROI</li>
                          <li>One4all Gift Card - ROI </li>
                          <li>Tony & Guy Voucher - ROI </li>
                          <li>MEvME Personal Training Gift Voucher - ROI </li>
                          <li>Confidence and Authenticity Masterclass Programme - ROI </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p><strong>Grand Prize:</strong> 1 x winner will win 1 x £15,000/€ cash.</p>
                  </li>
                  <li>
                    <p><strong>Wrap-Up Prize:</strong> 1 x winner will win 1 x £250/€ cash.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p><strong>Further Entry Details and Conditions</strong></p>
                <ol type="a">
                  <li><p>Pack codes are case-sensitive and must be entered exactly as shown.</p></li>
                  <li><p>The Promoter will not be responsible for telecommunication failure on the part of the entrant's service provider.</p></li>
                </ol>
              </li>
              <li>
                <p><strong>Further Prize Details and Conditions: </strong></p>
                <ol type="a">
                  <li>
                    <p><strong>Grand Prize and Wrap Up Draw Prize: </strong></p>
                    <ol type="i">
                      <li><p>Cash prize will be by BACS only. </p></li>
                      <li><p>Winner must have a valid UK /ROI registered bank account.  </p></li>
                      <li><p>The winner is responsible for providing correct bank account details and the Promoter will not be liable for any Prize payments made into the wrong bank account.</p></li>
                    </ol>
                  </li>
                  <li>
                    <p><strong>Instant Win Prizes: </strong></p>
                    <ol type="i">
                      <li><p>Winners will not receive any refund if the gift voucher is not used in full or in part.</p></li>
                      <li><p>The Prize must be taken as stated and no compensation will be payable if a winner is unable to use the Prize as stated. The winner will be liable for all costs and expenses not stated relating to claiming the prize.</p></li>
                      <li><p>The Promoter is not liable for third party products and the prizes are subject to the prize manufacturers' terms and conditions.</p></li>
                      <li>
                        <p><strong>UK Vouchers</strong></p>
                        <ol type="1">
                          <li>
                            <p>Spa and Beauty Indulgent Choice Voucher for Two</p>
                            <ol type="a">
                              <li><p>The voucher is valid for a range of spa days, beauty treatments or photoshoots UK wide.</p></li>
                              <li><p>The voucher is valid for 20 months from date of issue.</p></li>
                              <li><p>The voucher is valid across 42 locations in the UK.</p></li>
                              <li><p>Minimum age 18. </p></li>
                              <li><p>The voucher may be used at participating venues only.</p></li>
                              <li><p>Packages included in this flexible voucher vary depending on venue. Full details available upon booking.</p></li>
                              <li><p>There is a £20pp supplement for weekend bookings at Marriott.</p></li>
                              <li><p>Some treatments may be unsuitable due to medical conditions.</p></li>
                              <li><p>All dates are subject to availability.</p></li>
                              <li><p>Pre-booking is essential.</p></li>
                              <li><p>Please note that the offers included in this voucher are subject to change without notice - new offers may be added and others removed.</p></li>
                              <li><p>Please see <a href='https://www.buyagift.co.uk/spa-and-beauty' target="_blank" rel="noopener noreferrer">www.buyagift.co.uk/spa-and-beauty</a> for further details.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>Hair stylist sessions - EG Treatwell voucher</p>
                            <ol type="a">
                              <li><p>Gift Cards are valid for online bookings and eVoucher purchases made via <a href="https://www.treatwell.co.uk" target="_blank" rel="noopener noreferrer">treatwell.co.uk</a> or the Treatwell App.</p></li>
                              <li><p>The Gift Card balance will remain valid for twelve months after the date of purchase.</p></li>
                              <li><p>See <a href="https://www.treatwell.co.uk/gift-card" target="_blank" rel="noopener noreferrer">here</a> for further details.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>The Dining Out Gift Card</p>
                            <ol type="a">
                              <li><p>The Gift Card is Valid for 12 months.</p></li>
                              <li><p>Visit www.thediningoutgiftcard.co.uk for further details on restaurants and pubs across the UK.</p></li>
                              <li><p>Visit for terms and conditions: <a href="https://www.thediningoutgiftcard.co.uk/terms-and-conditions/" target="_blank" rel="noopener noreferrer" >www.thediningoutgiftcard.co.uk/terms-and-conditions/</a></p></li>
                            </ol>
                          </li>
                          <li>
                            <p>Restaurant Favourites Gift Voucher</p>
                            <ol type="a">
                              <li><p>The Voucher can be used across the UK in various restaurant chains.</p></li>
                              <li><p>Restaurant Favourites Gift Card is valid for 12 months from the date of activation.</p></li>
                              <li><p>See <a href="https://www.one4allrewards.co.uk/retailer/favourites/restaurant-favourites.html" target="_blank" rel="noopener noreferrer">here</a> for further details.</p></li> 
                            </ol>
                          </li>
                          <li>
                            <p>Cineworld/Odeon/Vue/ Omniplex/ IMC Cinemas Gift Card</p>
                            <ol type="a">
                              <li><p>Terms vary by Cinema.</p></li>
                              <li><p>Redemption period varies by Cinema.</p></li>
                              <li><p>Cineworld (GB) - see <a href="https://www.cineworld.co.uk/static/en/uk/help-and-frequently-asked-questions/gift-cards-vouchers" target="_blank" rel="noopener noreferrer">here</a> for further details.</p></li> 
                              <li><p>Odeon (GB and NI) - see <a href="https://www.odeon.co.uk/offers-membership/gift-cards/" target="_blank" rel="noopener noreferrer">here</a> for further details.</p></li>
                              <li><p>Vue (GB and NI) - see <a href="https://www.myvue.com/legal/terms-and-conditions#vue-gift-card-terms-and-conditions" target="_blank" rel="noopener noreferrer">here</a> for details. </p></li> 
                              <li><p>IMC Cinemas (NI)- see <a href="https://imccinemas.ie/gift-cards/" target="_blank" rel="noopener noreferrer">here</a> for details.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>Fashion Choice Gift Voucher  </p>
                            <ol type="a">
                              <li><p>The full value stored on the gift card is available to spend for 12 months from the date of purchase.</p></li>
                              <li><p>Can be used nationwide in the UK, both instore and online.</p></li>
                              <li><p>Please see <a href='https://www.fashionchoicecard.co.uk' target="_blank" rel="noopener noreferrer">fashionchoicecard.co.uk</a> for further details.</p></li>
                              <li><p>Brands included: Primark, M&amp;S, John Lewis &amp; Partners (Online only), Superdry (online only), Zalando, River Island (online only), Schuh (online only), JD Sports (online only), H&amp;M(online only), New Look (online only), Bohoo (online only), Bohoo Man (online only), Nasty Gal (online only), Dorothy Perkins (online only), TK Max (online only), Burton London (online only), Wallis (online only), Clarks, Calvin Klein and Tommy Hilfiger.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>Yoga Classes Choice Voucher for Two People </p>
                            <ol type="a">
                              <li><p>For 2 people.</p></li>
                              <li><p>Valid for 12 months from date of purchase. </p></li>
                              <li><p>Available at 39 locations.</p></li>
                              <li><p>See <a href="https://www.buyagift.co.uk/pampering/yoga-classes-choice-voucher-for-two-people-br-10587460.aspx" target="_blank" rel="noopener noreferrer">here</a> for further details.</p></li> 
                            </ol>
                          </li>
                          <li>
                            <p>Lifestyle Gift Voucher </p>
                            <ol type="a">
                              <li><p>Lifestyle Gift Vouchers are valid two years from date of purchase.</p></li>
                              <li><p>Please see <a href="https://www.thelifestylevoucher.co.uk/terms/" target="_blank" rel="noopener noreferrer">here</a> for terms and conditions.</p></li> 
                            </ol>
                          </li>
                          <li>
                            <p>MEvME Personal Training Gift Voucher</p>
                            <ol type="a">
                              <li><p>Valid for 6 months from date of purchase.</p></li>
                              <li><p>1-hour personal training via Zoom.</p></li>
                              <li><p>Please see <a href="https://www.mevme.co.uk/products/personal-training-gift-voucher" target="_blank" rel="noopener noreferrer">here</a> for further details.</p></li> 
                            </ol>
                          </li>
                          <li>
                            <p>Toni and Guy Voucher</p>
                            <ol type="a">
                              <li><p>Please see <a href="https://toniandguy.com/rewards/gift-card" target="_blank" rel="noopener noreferrer">here</a> for terms and conditions. </p></li> 
                              <li><p>Valid for 24 months from date of purchase.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>Confidence and Authenticity Masterclass Programme </p>
                            <ol type="a">
                              <li><p>Please see <a href="https://www.udemy.com/course/3x-confidence-and-authenticity-masterclass-programme/" target="_blank" rel="noopener noreferrer">here</a> for further details. </p></li> 
                              <li><p>5.5 hours of on-demand video and other resources with full lifetime access.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>City Academy Dance Voucher</p>
                            <ol type="a">
                              <li><p>Vouchers expire one year from the date of issue. </p></li>
                              <li><p>See <a href="https://www.city-academy.com/gift-voucher-terms-conditions" target="_blank" rel="noopener noreferrer">here</a> for terms and conditions. </p></li> 
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <p><strong>ROI Vouchers:</strong></p>
                        <ol type="1">
                          <li>
                            <p>AllGifts.ir Vouchers</p>
                            <ol type="a">
                              <li><p>Redeemable on site.</p></li>
                              <li><p>Can be used for a variety of spa/getaway, gym / wellbeing, hairstylists and many more experiences in ROI.</p></li>
                              <li><p>Vouchers are valid for 5 years from date of purchase and cannot be used to purchase goods or services directly from the GP. Vouchers are only valid for use within the validity period and are only redeemable on the <a href="https://allgifts.ie/" target="_blank" rel="noopener noreferrer">AllGifts</a> website. </p></li> 
                            </ol>
                          </li>
                          <li>
                            <p>AllGifts.ie Restaurant Voucher</p>
                            <ol type="a">
                              <li><p>The voucher can be used for a variety of Restaurant experiences throughout ROI.</p></li>
                              <li><p>Your AllGifts Voucher is valid for 5 years from the date it was bought; the expiry date is clary printed on each voucher.</p></li>
                              <li><p>See <a href="https://www.allgifts.ie/terms_and_conditions.aspx" target="_blank" rel="noopener noreferrer">here</a> for Terms and Conditions.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>Vue Cinema Gift Card</p>
                            <ol type="a">
                              <li><p>Gift Cards are subject to their own Terms and Conditions which can be found <a href=" https://www.myvue.com/legal/terms-and-conditions#vue-gift-card-terms-and-conditions" target="_blank" rel="noopener noreferrer">here</a></p>.</li>
                              <li><p>The balance of a Gift Card is valid for 12 months from the last transaction which includes activation, a balance enquiry or a purchase.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>Omniplex Gift Card </p>
                            <ol type="a">
                              <li><p>Gift Cards can be used throughout the ROI.</p></li>
                              <li><p>Denomination gift cards in ROI are valid for 5 years from the date of sale. For full terms and conditions, please visit <a href="https://shop.omniplex.ie/terms-and-conditions/" target="_blank" rel="noopener noreferrer">here</a></p></li>
                            </ol>
                          </li>
                          <li>
                            <p>IMC Cinemas Gift Card</p>
                            <ol type="a">
                              <li><p>Gift Cards are redeemable at Cinemas in the ROI.</p></li>
                              <li><p>Gift Cards are valid for 12 months from the date of purchase.</p></li>
                              <li><p>Gift Cards are subject to their own terms and conditions which can be found <a href=" https://imccinemas.ie/gift-cards/" target="_blank" rel="noopener noreferrer">here</a>.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>One4all Gift Card</p>
                            <ol type="a">
                              <li><p>Can be used in a variety of stores across the Republic of Ireland.</p></li>
                              <li><p>Including: River Island, New Look, Next.</p></li>
                              <li><p>One4all Gift Cards can be spent free of charges for 18 months following the purchase date. After this time a monthly charge of 90p will be deducted from funds remaining on the card, until the card balance reaches zero.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>Toni and Guy Voucher</p>
                            <ol type="a">
                              <li><p>Please see <a href="https://toniandguy.com/rewards/gift-card" target="_blank" rel="noopener noreferrer">here</a> for terms and conditions.</p></li>
                              <li><p>Valid for 24 months from date of purchase.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>MEvME Personal Training Gift Voucher</p>
                            <ol type="a">
                              <li><p>Valid for 6 months from date of purchase.</p></li>
                              <li><p>1-hour personal training via Zoom.</p></li>
                              <li><p>Please see <a href="https://www.mevme.co.uk/products/personal-training-gift-voucher" target="_blank" rel="noopener noreferrer">here</a> for further details.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p>Confidence and Authenticity Masterclass Programme </p>
                            <ol type="a">
                              <li><p>Please see <a href="https://www.udemy.com/course/3x-confidence-and-authenticity-masterclass-programme/" target="_blank" rel="noopener noreferrer">here</a> for further details.</p></li>
                              <li><p>5.5 hours of on-demand video and other resources with full lifetime access.</p></li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <p>For the avoidance of doubt, the selected voucher prizes do not include travel/transportation, accommodation, food, beverages, souvenirs, gratuities, car parking charges, excess baggage, additional excursions and attractions or any other costs of a personal nature (including spending money) that are not explicitly set out in these Terms and Conditions and neither the Promoter nor any provider of any part of the prize will be responsible for any such costs.</p>
                      </li>
                      <li>
                        <p>The validity period of each voucher is dependent on which voucher has been selected. Each voucher will be subject to its own terms and conditions and redemption date which will be printed on the voucher.</p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <p><strong>Winner Notification</strong></p>
                <ol type="a">
                  <li><p><strong>Instant Win:</strong> The winning entrants will be instantly notified on screen and will then be contacted via the email address provided on entry within 3 working days. Winners will be required to respond to confirm eligibility and acceptance of the prize within 14 days of receipt of email. In the event that a winner does not provide the required details within 14 days, the Promoter reserves the right to disqualify that winner and return the Prize to the Promotion Prize pool which may be won by another entrant during the Promotion Period.</p></li>
                  <li><p><strong>Grand Prize Draw & Wrap up Prize Draw: </strong> The provisional winner will be contacted via the email address/telephone number provided on entry within 7 working days of winner selection and will be asked to provide evidence of identity and eligibility. Reasonable efforts will be made to contact the winner. If the winner does not respond to the initial contact within 14 days, we reserve the right to disqualify that entrant and award the prize to a reserve selected in the same manner. Reserve winners may have less time to respond.</p></li>
                </ol>
              </li>
              <li>
                  <p><strong>Prize Acceptance:</strong> Fulfilment of the prizes will take place within 28 days of acceptance of the prizes. In the unlikely event that the prize does not arrive within 28 days, the winner must inform the Promoter by emailing <a href="mailto:treborownit@promowinners.com" target="_blank" rel="noopener noreferrer">treborownit@promowinners.com</a>. If the winner does not do so, the Promoter reserves the right to not reissue the prize or limit its value at its sole discretion.</p>
              </li>
              <li><p>For the avoidance of doubt, it is the responsibility of the participant to check junk/spam to ensure they are in receipt of promotional communication. The Promoter is not responsible for an entrant making a late claim on their win if this email is missed for any reason.</p></li>
              <li><p>Provided no objection is received, the surname and county of the winners will be made available to anyone who requests them by writing to <strong>JS13144 Trebor on Pack, PO Box 704, Pinner, HA5 9PF</strong>, within 3 months of the closing date. In the event they win, winners may request their surname and county are not published or request for the amount of information being published to be reduced during Winner Notification process. Without prejudice, the Promoter will provide this information to the Advertising Standards Authority on reasonable request.</p></li>
              <li><p>The Promoter may request that the winners participate in publicity arising from the Promotion. Participation is at the winner's discretion and is not a condition of prize acceptance.</p></li>
              <li>
                <p>The Promoter seeks to run a fair and secure promotion and prevent abuse and cheating. If you enter in a way that is not consistent with these Terms & Conditions, your entry (and any of your associated aliases) will be disqualified. Any Prize awarded may be void and recoverable, and the Promoter reserves the right to bar you from entering future promotions for a period of at least six months. For this reason, the Promoter reserves the right at any point to:</p>
                <ol type="a">
                  <li><p>Verify the eligibility of entrants and/or provisional winners by requesting such information it consider reasonably necessary for this purpose. Entry or a prize may be withheld until verification is completed.</p></li>
                  <li><p>Disqualify entries that are not made directly by the individual entering the Promotion. </p></li>
                  <li><p>Disqualify entries made using anonymous email services such as, but not limited to, GuerillaMail, Dispostable or Mailinator.</p></li>
                  <li><p>Disqualify bulk entries from individuals, trade, consumer groups or third parties, incomplete entries and entries submitted by macros or other automated means.</p></li>
                  <li><p>Disqualify entries beyond the maximum allowed, or those using techniques such as 'script', 'brute force', multiple SIM cards for text entries, masking identity by manipulating IP addresses, using aliases or identities other than their own or any other means.</p></li>
                  <li><p>Disqualify entrants who tamper with the entry process.</p></li>
                  <li><p>Disqualify entries which, in some other way, do not meet the requirements of these Terms & Conditions. </p></li>
                  <li><p>Cancel, modify or suspend the Promotion should it not be capable of running as planned for reasons including but not limited to tampering, unauthorised intervention, fraud, dishonesty, technical failures, or any other causes beyond the control of the Promoter which corrupt or affect the administration, security, fairness, integrity or proper conduct of this Promotion.</p></li>
                </ol>
              </li>
              <li><p>The prizes are not transferable or exchangeable and cannot be redeemed for monetary value or for any other form of compensation. If for any reason a prize is not available, the Promoter reserves the right, at its sole discretion to substitute that prize for another of equal or greater value.</p></li>
              <li><p>No responsibility can be taken for entries which are lost, delayed, corrupted, damaged, misdirected or incomplete or which cannot be delivered for any technical, delivery or other reason. Proof of sending will not be accepted as proof of receipt.</p></li>
              <li><p>The Promoter and its associated agencies and companies are not liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained (whether or not arising from any person's negligence) in connection with this Promotion (JS13144) or accepting or using the/a prize(s), except for any liability which cannot be excluded by law.  Nothing will exclude the Promotor's liability for death or personal injury as a result of its negligence.</p></li>
              <li><p>The Promoter will not be liable for any delay or failure to comply with its obligations for reasons beyond its reasonable control arising from but not limited to Acts of God, global or regional epidemic or pandemic, adverse weather conditions, fire, industrial dispute, war, terrorist activity, hostilities, political unrest, riots, civil commotion, plague or other natural calamities, or any other circumstances.</p></li>
              <li><p>The Promoter reserves the right to verify the eligibility of entrants. The Promoter may require such information as it considers reasonably necessary for this purpose and a prize may be withheld unless and until the Promoter is satisfied with the verification. </p></li>
              <li><p>The Promoter may refuse to award a prize in the event of any entrant's fraud, dishonesty, breach or non-entitlement under these Terms & Conditions or seek recovery of its value if the prizes have been awarded.</p></li>
              <li><p>The Promoter shall comply with all applicable requirements of the Data Protection Act 2018, the General Data Protection Regulation 2016 and any successor legislation or other applicable law. The personal information entrants provide will be used by the Promoter for the purpose of conducting this promotion and in accordance with the Promoters' Data Privacy Notice which can be found at <a href="http://eu.mondelezinternational.com/privacy-notice?sc_lang=en&siteId=gnvqPqY%2BC4h0vOchgR1oOul5vzaH6HHs" target="_blank" rel="noopener noreferrer">http://eu.mondelezinternational.com/privacy-notice</a>. The Promoter may disclose entrants' personal information to its contractors and agents to assist in conducting this Promotion (including any relevant promotional activity) or communicating with entrants. </p></li>
              <li><p>If any part/s of these Terms & Conditions are legally ruled to be invalid, illegal or unenforceable, the remainder will not be affected and will continue in full force and effect.</p></li>
              <li><p>By entering, you will be deemed to have agreed to be bound by these Terms & Conditions.</p></li>
              <li><p>If there is a discrepancy between these Terms & Conditions and those in any promotional material, these Terms & Conditions will prevail. </p></li>
              <li><p>These Terms & Conditions are governed by English law and their interpretation and application will be subject to the exclusive jurisdiction of the courts of England and Wales.</p></li>
            </ol>

            <p><strong>Promoter:</strong></p>
            <p>UK: Mondelez Europe Services GmbH - UK Branch with registered office at Cadbury House, Sanderson Road Uxbridge, UB8 1DH. Do not send promotion entries/claims to this address.</p>
            <p>ROI: Mondelez Europe Services GmbH - Ireland Branch whose address is at Malahide Road, Coolock, Dublin 5. Do not send promotion entries/claims to this address.</p>
            

          </div>
        </div>
      </section>
      </>;
  }else {
    return <Redirect to='/' />
  }
}