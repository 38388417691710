import React, { useEffect, useState } from 'react';
import ModalBackground from './ModalBackground';
import classList from 'react-classlist-helper';
import Loader from 'components/Loader/Loader';
import useEffectOnce from 'hooks/useEffectOnce';
import FocusTrap from 'focus-trap-react';
import analyticsService from 'services/analyticsService';
import './Modal.scss';

export default (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener("keyup", onKeyUp);
    return () => window.removeEventListener("keyup", onKeyUp);
  });

  useEffectOnce(() => {
    setTimeout(() => handleShowModal(), 0);
  });

  function onKeyUp (e) {
    if ((e.key === 'Escape' || e.key === 'Esc') && props.onDismiss) {
      props.onDismiss();
    }

    if (show && e.key === 'Enter' && props.onConfirm) {
      //props.onConfirm();
    }
  }

  const handleShowModal = () => {
    setShow(true);
    document.body.classList.add("overflow-hidden-mediumdown"); 
  }

  const handleOnDismissModal = () => {
    props.onDismiss();
    document.body.classList.remove("overflow-hidden-mediumdown");

    if(props.closeAnalytics){
      var analytics = props.closeAnalytics;
      
      analyticsService.logEvent(analytics.evnt, analytics.category, {
        action: analytics.action
      });
    }
  }


  const classMap = {
    "modal": true,
    "modal--show": show,
    "modal--loading": !!props.loading,
    "modal--panel": !!props.panel,
    [props.className]: !!props.className
  };

  const iconClassMap = {
    "modal__icon": true,
    "modal__icon--rounded": !!props.roundedIcon,
    "modal__icon--bordered": !!props.borderedIcon
  };

  return (
    <ModalBackground onDismiss={handleOnDismissModal} show={show}>
      { (props.loading || props.success) ? (
          <Loader complete={!!props.success} />
        ) : (
      <FocusTrap focusTrapOptions={props.focusTrapOptions}>
        <div className={classList(classMap)} role="dialog" aria-modal="true" tabIndex="-1" aria-live="assertive">
          { props.onDismiss && !props.hideClose &&
            <button className="modal__close" onClick={handleOnDismissModal}>Close</button>
          }
          { props.icon && (
              props.icon2x ? (
                <img className={classList(iconClassMap)} src={props.icon} srcSet={props.icon2x + " 2x, " + props.icon + " 1x"} alt={props.alt} />
              ) : (
                <img className={classList(iconClassMap)} src={props.icon} alt={props.alt} />
              )
          ) 
            
          }
         
          { props.title && 
            <h2 className="type-h3">{props.title}</h2>
          }
          { props.children }
        </div>
      </FocusTrap>
      )
    }
    </ModalBackground>
  );
};