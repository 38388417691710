import React from 'react';
import classList from 'react-classlist-helper';
import useSettingsContext from 'contexts/useSettingsContext';

import KvBgDskLg from 'assets/images/campaign/key_visuals/Header_Image_1920x950px.png';
import KvBgDskLg2x from 'assets/images/campaign/key_visuals/Header_Image_1920x950px@2x.png';
import KvBgDsk from 'assets/images/campaign/key_visuals/Header_Image_1400x693px.png';
import KvBgDsk2x from 'assets/images/campaign/key_visuals/Header_Image_1400x693px@2x.png';
import KvBgTabLg from 'assets/images/campaign/key_visuals/Header_Image_1024x507px.png';
import KvBgTabLg2x from 'assets/images/campaign/key_visuals/Header_Image_1024x507px@2x.png';
import KvBgTab from 'assets/images/campaign/key_visuals/Header_Image_640x861px.png';
import KvBgTab2x from 'assets/images/campaign/key_visuals/Header_Image_640x861px@2x.png';
import KvBgMobLg from 'assets/images/campaign/key_visuals/Header_Image_430x579px.png';
import KvBgMobLg2x from 'assets/images/campaign/key_visuals/Header_Image_430x579px@2x.png';
import KvBgMob from 'assets/images/campaign/key_visuals/Header_Image_320x430px.png';


import MopKvBgDskLg from 'assets/images/campaign/key_visuals/Mopup_Header_Image_1920x950px.png';
import MopKvBgDskLg2x from 'assets/images/campaign/key_visuals/Mopup_Header_Image_1920x950px@2x.png';
import MopKvBgDsk from 'assets/images/campaign/key_visuals/Mopup_Header_Image_1400x693px.png';
import MopKvBgDsk2x from 'assets/images/campaign/key_visuals/Mopup_Header_Image_1400x693px@2x.png';
import MopKvBgTabLg from 'assets/images/campaign/key_visuals/Mopup_Header_Image_1024x507px.png';
import MopKvBgTabLg2x from 'assets/images/campaign/key_visuals/Mopup_Header_Image_1024x507px@2x.png';
import MopKvBgTab from 'assets/images/campaign/key_visuals/Mopup_Header_Image_640x860px.png';
import MopKvBgTab2x from 'assets/images/campaign/key_visuals/Mopup_Header_Image_640x860px@2x.png';
import MopKvBgMobLg from 'assets/images/campaign/key_visuals/Mopup_Header_Image_430x578px.png';
import MopKvBgMobLg2x from 'assets/images/campaign/key_visuals/Mopup_Header_Image_430x578px@2x.png';
import MopKvBgMob from 'assets/images/campaign/key_visuals/Mopup_Header_Image_320x430px.png';



// EURO
import KvBgDskLg_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_1920x950px.png';
import KvBgDskLg2x_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_1920x950px@2x.png';
import KvBgDsk_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_1400x693px.png';
import KvBgDsk2x_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_1400x693px@2x.png';
import KvBgTabLg_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_1024x507px.png';
import KvBgTabLg2x_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_1024x507px@2x.png';
import KvBgTab_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_640x861px.png';
import KvBgTab2x_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_640x861px@2x.png';
import KvBgMobLg_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_430x579px.png';
import KvBgMobLg2x_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_430x579px@2x.png';
import KvBgMob_euro from 'assets/images/campaign/key_visuals/Euro/Header_Image_320x430px.png';

import MopKvBgDskLg_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_1920x950px.png';
import MopKvBgDskLg2x_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_1920x950px@2x.png';
import MopKvBgDsk_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_1400x693px.png';
import MopKvBgDsk2x_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_1400x693px@2x.png';
import MopKvBgTabLg_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_1024x507px.png';
import MopKvBgTabLg2x_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_1024x507px@2x.png';
import MopKvBgTab_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_640x861px.png';
import MopKvBgTab2x_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_640x861px@2x.png';
import MopKvBgMobLg_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_430x578px.png';
import MopKvBgMobLg2x_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_430x578px@2x.png';
import MopKvBgMob_euro from 'assets/images/campaign/key_visuals/Euro/Mopup_Header_Image_320x430px.png';


import './KeyVisual.scss';

export default (props) => {
  let settings = useSettingsContext();

  const classes = {
    "keyvis": true,
    "keyvis--mopup": !props.isMainPhase
  }

  return (
    <div className={classList(classes)}>

      
      <div className='keyvis__bg'>

        {
          settings.country === "ROI" ? (
          (props.isMainPhase) ? (
            <picture>    
              <source media="(min-width: 1401px)" srcSet={KvBgDskLg2x_euro + " 2x, " + KvBgDskLg_euro + " 1x"} type="image/png" />
              <source media="(min-width: 1025px)" srcSet={KvBgDsk2x_euro + " 2x, " + KvBgDsk_euro + " 1x"} type="image/png" />
              <source media="(min-width: 640px)" srcSet={KvBgTabLg2x_euro + " 2x, " + KvBgTabLg_euro + " 1x"} type="image/png" />
              <source media="(min-width: 431px)" srcSet={KvBgTab2x_euro + " 2x, " + KvBgTab_euro + " 1x"} type="image/png" />
              <source media="(min-width: 321px)" srcSet={KvBgMobLg2x_euro + " 2x, " + KvBgMobLg_euro + " 1x"} type="image/png" />
              <img src={KvBgMob_euro} alt="A chance to win €15K + 100s of confidence boosting prizes from Trebor"  />  
            </picture> 
          ) : (
            <picture>    
              <source media="(min-width: 1401px)" srcSet={MopKvBgDskLg2x_euro + " 2x, " + MopKvBgDskLg_euro + " 1x"} type="image/png" />
              <source media="(min-width: 1025px)" srcSet={MopKvBgDsk2x_euro + " 2x, " + MopKvBgDsk_euro + " 1x"} type="image/png" />
              <source media="(min-width: 640px)" srcSet={MopKvBgTabLg2x_euro + " 2x, " + MopKvBgTabLg_euro + " 1x"} type="image/png" />
              <source media="(min-width: 431px)" srcSet={MopKvBgTab2x_euro + " 2x, " + MopKvBgTab_euro + " 1x"} type="image/png" />
              <source media="(min-width: 321px)" srcSet={MopKvBgMobLg2x_euro + " 2x, " + MopKvBgMobLg_euro + " 1x"} type="image/png" />
              <img src={MopKvBgMob_euro} alt="A chance to win €250"  />  
            </picture> 
          )
          ) : (

            (props.isMainPhase) ? (
              <picture>    
                <source media="(min-width: 1401px)" srcSet={KvBgDskLg2x + " 2x, " + KvBgDskLg + " 1x"} type="image/png" />
                <source media="(min-width: 1025px)" srcSet={KvBgDsk2x + " 2x, " + KvBgDsk + " 1x"} type="image/png" />
                <source media="(min-width: 640px)" srcSet={KvBgTabLg2x + " 2x, " + KvBgTabLg + " 1x"} type="image/png" />
                <source media="(min-width: 431px)" srcSet={KvBgTab2x + " 2x, " + KvBgTab + " 1x"} type="image/png" />
                <source media="(min-width: 321px)" srcSet={KvBgMobLg2x + " 2x, " + KvBgMobLg + " 1x"} type="image/png" />
                <img src={KvBgMob} alt="A chance to win £15K + 100s of confidence boosting prizes from Trebor"  />  
              </picture> 
            ) : (
              <picture>    
                <source media="(min-width: 1401px)" srcSet={MopKvBgDskLg2x + " 2x, " + MopKvBgDskLg + " 1x"} type="image/png" />
                <source media="(min-width: 1025px)" srcSet={MopKvBgDsk2x + " 2x, " + MopKvBgDsk + " 1x"} type="image/png" />
                <source media="(min-width: 640px)" srcSet={MopKvBgTabLg2x + " 2x, " + MopKvBgTabLg + " 1x"} type="image/png" />
                <source media="(min-width: 431px)" srcSet={MopKvBgTab2x + " 2x, " + MopKvBgTab + " 1x"} type="image/png" />
                <source media="(min-width: 321px)" srcSet={MopKvBgMobLg2x + " 2x, " + MopKvBgMobLg + " 1x"} type="image/png" />
                <img src={MopKvBgMob} alt="A chance to win £250"  />  
              </picture> 
            )

          )
        }
      </div>
      {/*<div className="keyvis__bottom-layer">
        <picture>    
          <img src={KvBottomLayer} alt=""  />  
        </picture> 
      </div>
      <div className="keyvis__top-layer">
        <picture>    
          <source media="(min-width: 769px)" srcSet={KvTopLayer} type="image/png" />   
          <img src={KvTopLayerMob} alt=""  />  
        </picture> 
  </div> */}
    </div>
  )
}