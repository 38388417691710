import React, { useState } from 'react';
import useSettingsContext from 'contexts/useSettingsContext';
import codesValidator from 'validators/codesValidator';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ValidationMessage from 'components/Validation/ValidationMessage';
import analyticsService from 'services/analyticsService';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Form, { createOnChangeHandler } from './Form';
import TextField from 'components/Forms/TextField';
import "./CodeEntryForm.scss";

export default (props) => {
  let settingsContext = useSettingsContext();
  const [barcode, setBarcode] = useState(null);
  const [batchcode, setBatchcode] = useState(null);
  const [productType, setProductType] = useState(null);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  const createCommand = () => {
    return {
      barcode,
      batchcode,
      productType
    }
  }

  const onFormSuccess = () => {
    analyticsService.logEvent("forms", "Forms", {
      action: "CodesSuccess"
    });
  }

  const onFormFail = (error) => {
    analyticsService.logEvent("forms", "Forms", {
      action: "CodesFail",
      label: error
    });
  }

  const service = (command) => {
    props.onSubmit(command);
  }

  return (
    <Form 
      id="codes"
      formClass="code-entry"
      autoComplete="off"
      submitTitle="Enter"
      onSuccess={onFormSuccess}
      onFail={onFormFail}
      command={{
        model: createCommand,
        service: service
      }}
      validation={{
        validator: codesValidator,
        onValidation: setValidation
      }}
      submitAnalytics={{evnt: "form", category: "SubmitForm", action: "SubmitProofOfPurchaseForm"}}
    > 
      <p className="type-hpara">Enter your barcode and batch code (found on the back of pack). Don’t forget to keep your receipt.</p>
      <div className="code-entry__inputs">
        <div className="code-entry__codes-wrap">
          <div className="code-entry__codes">
            <fieldset className="form__fieldset">
              <TextField 
                id="barcode" 
                placeholder="e.g 7622300743659" 
                label="Barcode"
                maxLength="15"
                onChange={createOnChangeHandler(validation, 'barcode', setBarcode)}
                hasErrors={!!validation.errors.barcode}
              />
              <ValidationMessage errors={validation.errors.barcode} />  
            </fieldset>
            
            <fieldset className="form__fieldset">
              <TextField 
                id="batchcode" 
                placeholder="e.g 0B00B62021" 
                label="Batch code" 
                maxLength="15"
                onChange={createOnChangeHandler(validation, 'batchcode', setBatchcode)}
                hasErrors={!!validation.errors.batchcode}
              />
              <ValidationMessage errors={validation.errors.batchcode} />
            </fieldset>
            
          </div>        
        </div>
      </div>


      <ReCaptcha captchaSettings={settingsContext.captchaSettings} />
    </Form>
  )
}