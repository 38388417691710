import ValidationErrorCollection from './ValidationErrorCollection';

function validate(command) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('prize', 'Please select your prize');
  required('recaptcha2', 'Please confirm you are not a robot');

  
  
  
  if(command.country && (command.country === "UK" || command.country === "GB" || command.country ==="NI")) {
    required('address1', 'Please enter address line 1');
    required('town', 'Please enter your town');
    required('county', 'Please enter your county');
    required('postcode', 'Please enter your postcode');  
  }


  if (command.address1 && command.address1.length < 2) {
    messages.addError('address1', 'Please check address line 1 is a valid address');
  }

  
  if (command.town && command.town.length < 2) {
    messages.addError('town', 'Please check town is a valid town');
  }

  
  if (command.county && command.county.length < 2) {
    messages.addError('county', 'Please check county is a valid county');
  }


  if (command.isCustomPrize) {
    required('product1', 'Please select a product');
    required('product2', 'Please select a product');
    required('product3', 'Please select a product');
    required('product4', 'Please select a product');
    required('product5', 'Please select a product');
    required('product6', 'Please select a product');
  }

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
