import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryState from 'contexts/useCompetitionEntryContext';

import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import CodeEntry from './CodeEntry';
import PrizeBlock from 'components/Prize/PrizeBlock';
import Step from 'components/Step/Step';
import Link from 'components/Link/Link';
import Button from 'components/Button/Button';
import SocialButtons from 'components/SocialButtons/SocialButtons';

import NewsletterForm from 'containers/Forms/Newsletter';

import PlayButton from 'assets/images/campaign/play-btn.png';
//import ReactPlayer from 'react-player/vimeo'

import KeyVisual from './KeyVisual';

import How1 from 'assets/images/campaign/how-1.png';
import How12x from 'assets/images/campaign/how-1@2x.png';
import How2 from 'assets/images/campaign/how-2-rocket.png';
import How22x from 'assets/images/campaign/how-2-rocket@2x.png';
import How3 from 'assets/images/campaign/how-3.png';
import How32x from 'assets/images/campaign/how-3@2x.png';
import How4 from 'assets/images/campaign/how-4.png';
import How42x from 'assets/images/campaign/how-4@2x.png';

import PrizeTier1 from 'assets/images/campaign/top_tier_prize_mobile.png';
import PrizeTier12x from 'assets/images/campaign/top_tier_prize_mobile@2x.png';
import PrizeTier1Large from 'assets/images/campaign/top_tier_prize.png';
import PrizeTier1Large2x from 'assets/images/campaign/top_tier_prize@2x.png';
import PrizeTier2 from 'assets/images/campaign/prize_boosting--mobile.png';
import PrizeTier22x from 'assets/images/campaign/prize_boosting--mobile@2x.png';
import PrizeTier2Large from 'assets/images/campaign/prize_boosting.png';
import PrizeTier2Large2x from 'assets/images/campaign/prize_boosting@2x.png';

// EURO
import PrizeTier1_euro from 'assets/images/campaign/top_tier_prize_mobile--euro.png';
import PrizeTier12x_euro from 'assets/images/campaign/top_tier_prize_mobile--euro@2x.png';
import PrizeTier1Large_euro from 'assets/images/campaign/top_tier_prize--euro.png';
import PrizeTier1Large2x_euro from 'assets/images/campaign/top_tier_prize--euro@2x.png';

import CountrySelect from './CountrySelect';

import ProductRange from 'components/ProductRange/ProductRange';

import './Home.scss';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';

export default () => {
  const [settings, setSettings ] = React.useContext(SettingsContext);
  let settingsState = useSettingsContext();
  let competitionEntryState = useCompetitionEntryState();
  let history = useHistory();
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let compPhase = settings.competitionStatus.currentPhase;
  let competitionIsOpen = compState === "Open";
  let competitionIsClosed = compState === "Closed";
  let competitionIsMopup = compPhaseId === "mop-up";
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(!settings.country);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let currency = (settings.country === "ROI" ? "€" : settings.country === "GB" ?  "£" : settings.country === "NI" ?  "£" : "£/€");


  const handleCountrySelected = (data) => {
    sessionStorage && sessionStorage.setItem('treborownit.country', data.country);
    settingsState.setCountry(data.country);
    setShowCountryModal(false);
    
  }

  const handleEnterNow = async () => {
    if (settings.country !== "ROI") {
      setShowEntryModal(true)
    } else {
      history.push("/registration")
    }
  }

  const handleCodeEntry = () => {
    history.push("/registration")
  }

  const renderSteps = (steps) => {
    return steps.map((step, index) => {
      if (competitionIsMopup && index === 3) return;
      return (
        <Step key={step.number} {...step} />
      )
    })
  }


  const renderEntryCta = () => {
    if (!settings.country) return null;

    return <Button 
      wide
      type="submit" 
      title="Enter" 
      className="button--wide"
      onClick={handleEnterNow}
    />
  }

  const renderIntroMessage = (compState) => {
    switch (compState) {
      case 'Open':
        return <>
          <div className="home__intro">
            { !competitionIsMopup ? (
              <>
                <p className="home__intro-title">
                  Own It 
                </p>
                <p className="home__intro-strapline">
                  Bling up your confidence with trebor
                </p>
                <div className="home__intro-body">
                  <p>Here's your chance to <strong>OWN IT</strong> with Trebor</p>
                  <p>
                   Choose whatever's a bit of you from 100s of instant confidence boosts, including amazing new experiences for this year like PT sessions, dance classes, confidence masterclasses and loads more.
                  </p>
                  <p>Plus, you'll be entered into the big one: {currency}15K in cash. Ka-ching!</p>
                </div>
              </>
            ) : (
              <>
                <div className="home__intro-body">
                  <p>Shucks, our confidence boosting prizes have all been snapped up. But how does {currency}250 in cash sound?</p>
                  <p>Grab a Trebor pack and enter now to own it.</p>
                  <p>You've got this!</p>
                </div>
              </>
            )}
          </div>
          <div className="home__cta">
            { renderEntryCta() }
          </div>
        </>

      case 'NotStarted':
        return <>
          <div className="home__intro">
            <p className="home__intro-title">
              Own It 
            </p>
            <p className="home__intro-strapline">
              Unleash your inner confidence with trebor
            </p>
            <p className="home__intro-strapline">
              Coming 24th July
            </p>
          </div>
        </>

      case 'Closed':
        return <>
          <div className="home__intro">
           
            <p className="type-h2">Keep on owning it</p>
            <div className="home__intro-body">
              <p>
                Sorry but the big {currency}15K in cash and OWN IT promotion is now closed. Wanna stay up to date for more competitions and news? Of course you do! Follow us.
              </p>
            </div>
          </div>
          <div className="home__cta">
            <SocialButtons />
          </div>
        </>
    }
  }

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Win {currency}15K plus 100s of Confidence Boosting Prizes with Trebor</title>
      </Helmet>
      { competitionIsOpen &&
        <>
          <CodeEntry 
            show={showEntryModal}
            onDismiss={() => setShowEntryModal(false)}
            onEntry={handleCodeEntry} 
          />


          <CountrySelect 
            show={showCountryModal}
            onCountrySelected={handleCountrySelected}
          />
        </>
      }
      <h1 className="visually-hidden">Trebor Own It</h1>
      <section className="layout-wrapper home__banner">
        <div className="home__banner-container">
          <div className="home__banner-content">
            <KeyVisual isMainPhase={!competitionIsMopup}>
              { competitionIsOpen && !competitionIsMopup && renderEntryCta() }
            </KeyVisual>
          </div>
        </div>
      
        <div className="home__entrybar" >
          { renderIntroMessage(compState) }
        </div>
      </section>

      

      { competitionIsOpen && !competitionIsMopup && 
        <section className="layout-wrapper layout-wrapper--padding home__prize-blocks">
          <div className="layout-container layout-container--center">
            <h2 className="type-h2 ">What Prizes Can I Own?</h2>

            <div className="home__prize-block-list">

              {
                settings.country === "ROI" ? (
                  <PrizeBlock 
                    key="1" 
                    type='banner'
                    alt="€15k" 
                    image={PrizeTier1_euro} 
                    image2x={PrizeTier12x_euro} 
                    imageLarge={PrizeTier1Large_euro} 
                    imageLarge2x={PrizeTier1Large2x_euro} 
                  />
                ) : (
                  <PrizeBlock 
                    key="1" 
                    type='banner'
                    alt="£15k" 
                    image={PrizeTier1} 
                    image2x={PrizeTier12x} 
                    imageLarge={PrizeTier1Large} 
                    imageLarge2x={PrizeTier1Large2x} 
                  />
                )
              }

              <PrizeBlock 
                key="2" 
                type='horizontal' 
                alt="Instant confidence boosting prizes" 
                image={PrizeTier2} 
                image2x={PrizeTier22x} 
                imageLarge={PrizeTier2Large} 
                imageLarge2x={PrizeTier2Large2x} 
                description="<div><ul><li>Confidence Masterclasses</li><li>Clothing vouchers for a fresh look</li><li>Restaurant vouchers</li><li>Personal training sessions</li></ul><ul><li>Your OWN IT hairstylist</li><li>Mindfulness sessions</li><li>Tone it to OWN IT with gym passes</li><li>And loads more to OWN IT</li></ul></div>" 
              />


            </div>
          </div>
        </section>
      }

      { (competitionIsOpen || competitionIsMopup) &&
        <section className={competitionIsMopup ? "layout-wrapper layout-wrapper--padding home__how-to-play" : "layout-wrapper layout-wrapper--padding"}  id="how-to-play">
          <div className="layout-container layout-container--center ">
            <h2 className="type-h2">How to own it</h2>
            <div className="home__steps">


              {settings.country !== "ROI" ? (
                renderSteps([
                  { number: 1, description: "Buy any pack <br/> of Trebor", image: How1, image2x: How12x, alt:"Trebor Products" },
                  { number: 2, description: "Enter your details and the barcode/batch code", image: How2, image2x: How22x, alt:"Bar and Batch Codes" },
                  { number: 3, description: "Find out if you’ve won and get entered into the prize draw", image: How3, image2x: How32x,  alt: "Win" }
                ])
              ) : (
                renderSteps([
                  { number: 1, description: "Fill in your details", image: How4, image2x: How42x, alt:"Form" },
                  { number: 2, description: "Find out if you’ve won and get entered into the prize draw", image: How3, image2x: How32x,  alt: "Win" }
                ])
              
              )}

            </div>
            
            <div className="home__cta">
              { renderEntryCta() }
            </div>


             {competitionIsMopup ? ( 

             <div className="home__footer">
              <p>All entries will go in to WIN the big {currency}250 cash prize. Winner will be drawn within 7 working days from the end of the Wrap Up Draw Period.</p>
              </div>

              ) : (  
              
              <div className="home__footer">
              <p>Instantly find out if you've won an OWN IT confidence boost.</p>
              <p>All entries will go in to WIN the big {currency}15K cash prize. Winners will be drawn within 5 working days from the end of the promotion period.</p>
              </div>)
          }  

          
            
          </div>
        </section>
        
      }

      { competitionIsOpen && !competitionIsMopup && 
        <section className={"layout-wrapper home__signup" + (settings.country === "ROI" ? " layout-wrapper--padding" : "")}>
          <div className="layout-container ">
            <div className='home__signup__form-block'>
              <div className='type-h2'>Your Inbox.<br/>Own It</div>
              <p>Do you want to be kept in the know about all things Trebor? Of course you do! Just tick the boxes and we’ll send you the latest on new releases, competitions and more.</p>
              <NewsletterForm />
            </div>                       
          </div>
        </section>
      }

      {
        competitionIsClosed &&
        <ProductRange />
      }

      {
        !competitionIsClosed &&
        <SummaryTsandCs />
      }
      

      
    </div>
  );
}