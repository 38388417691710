import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";

import prizeClaimService from 'services/prizeClaimService';
import prizeClaimValidator from 'validators/prizeClaimValidator';

import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';

import Button from 'components/Button/Button';
import TextField from 'components/Forms/TextField';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.svg';
import SpaceshipStripImage from 'assets/images/campaign/spaceship-strip.png';
import SelectField from 'components/Forms/SelectField';

import SocialButtons from 'components/SocialButtons/SocialButtons';
import ProductRange from 'components/ProductRange/ProductRange';

import Form, { createOnChangeHandler } from './Form';

import './Claim.scss';
import './Form.scss';


export default (props) => {
  let [settings, setSettings] = React.useContext(SettingsContext);
  let { token } = props;
  let [claim, setClaim] = useState();
  let [sent, setSent] = useState(false);
  let [prize, setPrize] = useState('');
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [town, setTown] = useState('');
  let [county, setCounty] = useState('');
  let [postcode, setPostcode] = useState('');
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);
  
  useEffect(() => {
    let account = settings.account;
    if (!account) return;


    if (account.address1) {
      setAddress1(account.address1);
    }

    if (account.address2)  {
      setAddress2(account.address2);
    }

    if (account.city) {
      setTown(account.city);
    }

    if (account.county) {
      setCounty(account.county);
    }

    if (account.postcode) {
      setPostcode(account.postcode);
    }

    if (account.prize) {
      setPrize(account.prize);
    }
    
  }, [settings.account])

  

  const renderPrize = () => {
    var prizes = claim.prizes;
    return prizes.map((prize, index) => {
      return (
        <option value={prize.code}>{prize.name}</option>
      )
    })
  }

  const createCommand = () => {
    let command = {
      address1,
      address2,
      town,
      county,
      postcode,
      token,
      prize,
      country: claim.country,
      recaptcha2
    };

    return command;
  }

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      //setRecaptcha2(val);
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }

  useEffect(() => {   
    if(claim !== undefined && claim.canClaim !== undefined && claim.canClaim === true)
    {
      window.setTimeout(function(){
        try 
        {
          let $grecaptchav2 = document.querySelectorAll('.g-recaptcha-v2')[0];
          if ($grecaptchav2) {
           
              window.grecaptcha.ready(() => {
                window.grecaptcha.render($grecaptchav2, {
                  'sitekey' : '6LdL2fUjAAAAAOoc2lfJC0TdN5ovsuxgkeJcSUv1',
                  'callback': handleReCaptchaV2,
                  'expired-callback': handleReCaptchaV2Expired
                });
            });
          }
        }catch{}

      }, 1000);
    
    }
  
  }, [claim]);

  useEffect(() => {
    async function fetchData() {
      let result = await prizeClaimService.getPrizeClaim(token);
      setClaim(result);
      
    }

    if (token && !claim) {
      fetchData();
    }
  }, [claim, token]);

  if (token && !claim) {
    return (
      <div>Loading...</div>
    )
  }


  const onFormSuccess = () => {
    setSent(true);
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
  }

  if (!claim.canClaim) {
    return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Choose Your Boost | Trebor Own It</title>
      </Helmet>
      <section className="layout-general layout-wrapper">
        <div className="layout-container layout-container--slim layout-container--center">
          <h1 className="type-h2 ">Sorry!</h1>
          <p className="form__intro">{claim.error.message}</p>  
        </div>
      </section>
      </>
    )
  }

  return ( <>
   <Helmet>
      <meta charSet="utf-8" />
      <title>Choose Your Boost | Trebor Own It</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
    
        { !sent ? 
          (
            <>
                <h1 className="type-h2 ">Choose your boost</h1>
                <p className="form__intro form__intro--slim">
                  Pick your prize below and fill in your deets so we can send you your prize 
                </p>

                <Form 
                  id="claim"
                  submitTitle="Claim"
                  formClass="form form--slim"
                  onSuccess={onFormSuccess}
                  command={{
                    model: createCommand,
                    service: prizeClaimService.addPrizeClaim
                  }}
                  validation={{
                    validator: prizeClaimValidator,
                    onValidation: setValidation
                  }}
                >
                  
                  { validation.errors.hasErrors && validation.acknowledged &&
                    <ValidationSummary 
                      errors={validation.errors}
                      handlePropertyErrors={['RecaptchaToken']}
                    />
                  }

                <fieldset className="form__fieldset"> 
                  <SelectField 
                    label="*Please select your prize"
                    id="prizeId"
                    onChange={createOnChangeHandler(validation, 'prize', setPrize)}
                    value={prize}
                    hasErrors={!!validation.errors.prize}
                  >
                    <option value="" >Select an option</option>                    
                    { renderPrize() }                    
                  </SelectField>
                  <ValidationMessage errors={validation.errors.prize} />
                </fieldset>

                { (claim.country === "UK" || claim.country === "GB" || claim.country === "NI") && 
                <>

                  <fieldset className="form__fieldset">
                    <TextField 
                      label="*Address line 1"
                      id="address1"
                      onChange={createOnChangeHandler(validation, 'address1', setAddress1)}
                      value={address1}
                      hasErrors={!!validation.errors.address1}
                    />
                    <ValidationMessage errors={validation.errors.address1} />
                  </fieldset>

                  <fieldset className="form__fieldset">
                    <TextField 
                      label="Address line 2"
                      id="address2"
                      onChange={createOnChangeHandler(validation, 'address2', setAddress2)}
                      value={address2}
                      hasErrors={!!validation.errors.address2}
                    />
                    <ValidationMessage errors={validation.errors.address2} />
                  </fieldset>

                  <fieldset className="form__fieldset">
                    <TextField
                      label="*Town/City"
                      id="town"
                      onChange={createOnChangeHandler(validation, 'town', setTown)}
                      value={town}
                      hasErrors={!!validation.errors.town}
                    />
                    <ValidationMessage errors={validation.errors.town} />
                  </fieldset>

                  <fieldset className="form__fieldset">
                    <TextField 
                      label="*County"
                      id="county"
                      onChange={createOnChangeHandler(validation, 'county', setCounty)}
                      value={county}
                      hasErrors={!!validation.errors.county}
                    />
                    <ValidationMessage errors={validation.errors.county} />
                  </fieldset>

                  <fieldset className="form__fieldset">
                    <TextField 
                      label="*Postcode"
                      id="postcode"
                      onChange={createOnChangeHandler(validation, 'postcode', setPostcode)}
                      value={postcode}
                      hasErrors={!!validation.errors.postcode}
                    />
                    <ValidationMessage errors={validation.errors.postcode} />
                  </fieldset>
                  </>
                }

                  <ReCaptcha captchaSettings={settings.captchaSettings} />

                  <div class="g-recaptcha-v2" data-sitekey="6LdL2fUjAAAAAOoc2lfJC0TdN5ovsuxgkeJcSUv1"
                  data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
                  <ValidationMessage errors={validation.errors.recaptcha2} />

                  { validation.errors.hasErrors && !validation.acknowledged &&
                    <Modal 
                      panel 
                      hideClose
                      icon={AlertIcon}
                      onDismiss={onDismissModal}
                      onConfirm={onDismissModal}
                      title="Oops">
                      <ValidationSummary 
                        modal
                        defaultMessage="Please check you have entered all fields correctly."
                        errors={validation.errors}
                        handlePropertyErrors={['RecaptchaToken']}
                      />
                      { !validation.errors.globalErrors.length ? (
                          <Button title="Try again" onClick={onDismissModal} />
                        ) : (
                          <Button title="Back to homepage" href="/" />
                        )
                      }
                    </Modal>
                  }
                </Form>
            </>
          ) : (
            <>
              <img className="claim__image" src={ SpaceshipStripImage } alt="" />
              <h1 className="type-h2 type-h2--gold">Thank you!</h1>
              <p className="form__intro">You're prize will be winging its way to you shortly!</p>

                
            </>
          )
        }

        </div>
      </section>
    </> 
  );
}