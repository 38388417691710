import ValidationErrorCollection from './ValidationErrorCollection';

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const ALPHA_REGEX = /^[A-Za-z]+$/;
const ADDRESS_REGEX =/^[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ-0-9'´’‘#,\.]+( [a-zA-ZÀ-ÖØ-ŷ-öø-ÿ-0-9'´’‘#,\.]+)*$/;
const NUMBERS_REGEX = /^(?:[+\d].*\d|\d)$/;
const NAME_REGEX2 = /^(((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘)))+( (((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘))))*$/;
const EIRCODE_REGEX = /^([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}$/;
const POSTCODE_REGEX = /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;


function validate(command, settings) {
  let messages = new ValidationErrorCollection();
  const d = new Date();
  let year = d.getFullYear();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('firstName', 'Please enter a first name');
  required('lastName', 'Please enter a last name');
  required('email', 'Please enter an email address');
  required('country', 'Please select a country');
  required('terms', 'Please confirm you agree to our terms & conditions and privacy policy');
  required('legalAge', 'Please confirm you are over 18');
  required('recaptcha2', 'Please confirm you are not a robot');
  //required('dob', 'Please enter your date of birth');


  if (command.country === "ROI") {
    required('address1', 'Please enter the first line of your address');
    required('city', 'Please enter your city');
    required('county', 'Please enter your county');
    required('postcode', 'Please enter your postcode/eircode');

    if (command.address1!== '' && !ADDRESS_REGEX.test(command.address1)) {      
      messages.addError('address1', 'Please enter a valid address');      
    }

    if (command.address2!== '' && !ADDRESS_REGEX.test(command.address2)) {      
      messages.addError('address2', 'Please enter a valid address');
    }

    if (command.city && !NAME_REGEX2.test(command.city)) {
      messages.addError('city', 'Please enter a valid city');
    }

    if (command.county && !NAME_REGEX2.test(command.county)) {
      messages.addError('county', 'Please enter a valid county');
    }

    if(command.country === "NI" && command.postcode!== '' && !POSTCODE_REGEX.test(command.postcode)) {
      messages.addError('postcode', 'Please enter a valid Postcode');
    }
    else if (command.country === "ROI" && command.postcode!=='' && !EIRCODE_REGEX.test(command.postcode)) {
      messages.addError('postcode', 'Please enter a valid Eircode');
    }
  } 

  if (command.firstName && !NAME_REGEX2.test(command.firstName)) {
    messages.addError('firstName', 'Please enter a valid first name');
  }

  if (command.lastName && !NAME_REGEX2.test(command.lastName)) {
    messages.addError('lastName', 'Please enter a valid last name');
  }

  if (command.email && !EMAIL_REGEX.test(command.email)) {
    messages.addError('email', 'Please enter a valid email address');
  }

  if (command.email && command.email.toLowerCase() !== command.emailConfirm.toLowerCase()) {
    messages.addError('emailConfirm', 'Please confirm your email');
  }

  let mobileStrippedOfWhitespace = command.phoneNumber.replace(/\s+/g, '');

  if ((mobileStrippedOfWhitespace === '' || mobileStrippedOfWhitespace.split('').length > 13 || !NUMBERS_REGEX.test(mobileStrippedOfWhitespace))) {
    messages.addError('phoneNumber', 'Please enter a valid mobile number');
  }

  if (command.dob && !command.dob.isValid) {
    messages.addError('dob', command.dob.error || "Please enter a valid date of birth");
  }

  if (command.dob && command.dob.isValid && command.dob.date.getFullYear() <= (year - 120) ) {
    messages.addError('dob', command.dob.error || "Please enter a valid date of birth");
  }

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
