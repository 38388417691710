import React from 'react';
import classList from 'react-classlist-helper';
import { ReactComponent as InSvg } from 'assets/images/instagram.svg';
import { ReactComponent as TwSvg } from 'assets/images/twitter.svg';


import './SocialButtons.scss';

export default (props) => {
  const classMap = {
    "social-buttons": true,
    "social-buttons--ilustrations": !!props.hasIlustrations,
    [props.className]: !!props.className,
  };

  return (
    <ul className={classList(classMap)} >
     <li className="social-buttons__item">
        <a href="https://twitter.com/trebor_uk" target="_blank" title="Trebor UK on Twitter" rel="noopener referrer">
          <TwSvg />
        </a>
     </li>
     <li className="social-buttons__item">
        <a href="https://www.instagram.com/trebor_uk/" target="_blank" title="Trebor UK on Instagram" rel="noopener referrer">
          <InSvg />
        </a>
     </li>
    </ul>
  )
  
}