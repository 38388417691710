import React from 'react';
import useSettingsContext from 'contexts/useSettingsContext';
import { ReactComponent as FbSvg } from 'assets/images/facebook.svg';
import { ReactComponent as InSvg } from 'assets/images/instagram.svg';
import { ReactComponent as TwSvg } from 'assets/images/twitter.svg';
import { ReactComponent as MdlzSvg } from 'assets/images/mondelez-logo.svg';
import Link from 'components/Link/Link';
import './Footer.scss';

export default function (props) {
    var settings = useSettingsContext();

    return (
      <footer className="footer">
        <div className="footer__menu">
          <nav className="footer__menu__links">
              { props.showTerms &&
                <Link 
                analytics={{evnt: "siteFooterLink", category: "TermsAndConditionsLink", action: "OpenTermsAndConditions"}}
                to="/terms-and-conditions"
                route
                title="Terms and Conditions" >T&amp;Cs</Link>
              }
              <Link 
                analytics={{evnt: "siteFooterLink", category: "PrivacyPolicyLink", action: "OpenPrivacyPolicy"}}
                href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=en&siteId=gnvqPqY%2bC4h0vOchgR1oOul5vzaH6HHs"
                target="_blank"
                title="Privacy Policy">Privacy Policy</Link>

              <Link 
                analytics={{evnt: "siteFooterLink", category: "CookiePolicyLink", action: "OpenCookiePolicy"}}
                href="/cookie-policy"
                target="_blank"
                title="Cookie Policy" >Cookie Policy</Link>

              <Link 
                analytics={{evnt: "siteFooterLink", category: "ContactUsLink", action: "OpenContactUs"}}
                href="https://contactus.mdlzapps.com/form?siteId=gnvqPqY%2bC4h0vOchgR1oOul5vzaH6HHs"
                target="_blank"
                title="Contact Us" >Contact Us</Link>
          </nav>
          
          <p className="footer__menu__copyright">&copy; {new Date().getFullYear()} Mondelez UK &amp; Ireland - All Rights Reserved.</p>
        </div>

        <div className="footer__social">
          <Link 
                analytics={{evnt: "siteFooterLink", category: "MondelezInternationalLink", action: "OpenMondelezInternationalLink"}}
                href="http://www.mondelezinternational.com/"
                target="_blank"
                title="Mondelez International"
                className="footer__logo" >
                  <MdlzSvg />
            </Link>
          
          <nav className="footer__social-links">
            <Link 
                analytics={{evnt: "socialLinks", category: "FacebookLink", action: "OpenFacebookLink"}}
                href="https://www.facebook.com/Trebor.UK/"
                target="_blank"
                title="Trebor UK on Facebook" >
                  <FbSvg />
            </Link>
            <Link 
                analytics={{evnt: "socialLinks", category: "TwitterLink", action: "OpenTwitterLink"}}
                href="https://twitter.com/trebor_uk"
                target="_blank"
                title="Trebor UK on Twitter" >
                  <TwSvg />
            </Link>
            <Link 
                analytics={{evnt: "socialLinks", category: "InstagramLink", action: "OpenInstagramLink"}}
                href="https://www.instagram.com/trebor_uk/"
                target="_blank"
                title="Trebor UK on Instagram" >
                  <InSvg />
            </Link>
            
          </nav>
        </div>
      </footer>
    );
}